import {
  Box,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import threedots from "assets/svg/light/threedots.svg";
import {
  deletePatientMedications,
  updatePatientMedications,
} from "redux/features/Medications/MedicationsSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Text from "components/common/Typography/Text";
import { useQueryClient } from "@tanstack/react-query";
import CropSquareSharpIcon from "@mui/icons-material/CropSquareSharp";
import trash from "../../../../../../../src/assets/svg/light/trash.svg";

const Accordian = ({ currentMed, isActive, classes, data, id }) => {
  let theme = useTheme();
  const queryClient = useQueryClient();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);
  // To track if a specific medication is being deleted;
  const [isDeleting, setIsDeleting] = useState(false);
  const { deletingMedicationInProgress, deletedMedicationSuccessfully } =
    useSelector((state) => state.medications);

  const handleMenuOpen = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMedsDelete = () => {
    setIsDeleting(true);
    dispatch(deletePatientMedications(patientId, id));
  };

  useEffect(() => {
    if (isDeleting && deletedMedicationSuccessfully) {
      handleMenuClose();
      queryClient.invalidateQueries({ queryKey: ["medications", patientId] });
      setIsDeleting(false);
    }
  }, [deletedMedicationSuccessfully]);

  const updateMedication = () => {
    const payload = {
      ...currentMed,
      end_date: new Date(),
    };
    dispatch(updatePatientMedications(patientId, id, payload));
    setAnchorEl(null);
  };

  const accordianToggle = () => {
    if (data?.instructions) {
      setExpanded(!expanded);
    }
  };

  return (
    <Box data-testid="accordion-ui-medications-mainbox">
      <Accordion
        expanded={expanded}
        onClick={accordianToggle}
        data-testid="accordion-ui-medications-details"
        sx={{ boxShadow: "none" }}
        className={classes?.accordionRoot}
        TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          sx={{
            background: isActive ? "#E5EBFF" : "rgb(240, 240, 240)",
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper": {
              marginRight: "8px",
            },
            borderRadius: expanded ? "8px 8px 0px 0px" : "8px 8px 8px 8px",
          }}
          expandIcon={data?.instructions ? <ExpandMoreIcon /> : null}
          aria-controls={`panel-content`}
          id={`panel-header`}
          data-testid={`panel-header-medications-details`}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center",
              }}>
              <Box>
                <Text
                  id="medication-heading"
                  fontWeight="700"
                  sx={{ margin: "0px" }}
                  variant="h3"
                  color={"black"}>
                  <span data-testid="accordian-title-medications-details">
                    {data?.drug_name}
                  </span>
                </Text>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "4px",
                  padding: "4px",
                }}>
                <Text
                  data-testid="scd-medication-detail-dosage-quantity-id"
                  variant={"body2"}
                  sx={{ marginLeft: "10px", fontWeight: "500" }}>
                  {data?.dosage_quantity_value
                    ? `${data?.dosage_quantity_value ? data?.dosage_quantity_value : ""} ${data?.dosage_quantity_unit ? data?.dosage_quantity_unit : ""}`
                    : ""}
                </Text>
                <Text
                  variant={"body2"}
                  data-testid="scd-medication-detail-refills-id"
                  sx={{ marginLeft: "10px", fontWeight: "500" }}>
                  Refills:{" "}
                  {data?.refills_allowed === null ? "" : data?.refills_allowed}
                </Text>
                <Text
                  variant={"body2"}
                  data-testid="scd-medication-detail-dispense-id"
                  sx={{ marginLeft: "10px", fontWeight: "500" }}>
                  Dispense: {data?.dispense_quantity ?? ""}
                </Text>
              </Box>
            </Box>

            <img
              data-testid="medication-delete-menu-open-id"
              style={{
                marginRight: "4px",
                width: "15px",
                height: "15px",
                flexShrink: 0, // Prevent wrapping
              }}
              src={threedots}
              onClick={(event) => {
                event.stopPropagation();
                handleMenuOpen(event);
              }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            background: isActive
              ? `${theme.palette.common.softBlue}`
              : "rgb(240, 240, 240)",
            borderRadius: "0px 0px 8px 8px",
            marginBottom: "8px",
          }}
          data-testid="accordion-details">
          <Grid container rowSpacing={3} sx={{ marginBottom: "5px" }}>
            <Grid sx={{ marginLeft: "38px" }} item xs={6} sm={6} md={6} lg={6}>
              <Text variant="body2" sx={{ fontWeight: "600" }}>
                Instructions
              </Text>
              <Text
                data-testid="scd-medication-detail-instruction-id"
                variant="body2">
                {data?.instructions ? data?.instructions : ""}
              </Text>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        data-testid="menu-medications"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiMenuItem-root": {
            fontSize: "0.875rem",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}>
        {currentMed && (
          <MenuItem onClick={updateMedication}>
            <CropSquareSharpIcon sx={{ marginRight: "10px" }} />
            Set As Inactive
          </MenuItem>
        )}
        <MenuItem
          data-testid="delete-option-for-medications"
          onClick={handleMedsDelete}>
          <img
            src={trash}
            alt={trash}
            style={{ marginRight: "10px" }}
            data-testid="trash-icon-medications"
          />
          Cancel Prescription
          {deletingMedicationInProgress && (
            <CircularProgress
              sx={{ marginLeft: "4px" }}
              size={"20px"}
              color="inherit"
            />
          )}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Accordian;
