import { apiGet, apiPost, parseQueryOptions } from "./base";

export const fetchPatient = async (patientId) => {
  const url = `/api/patients/${patientId}/`;
  const response = await apiGet(url);
  return response.data;
};

export const fetchPatients = async (queryOptions, { pageParam }) => {
  const url = `/api/patients/?${parseQueryOptions(queryOptions)}`;
  const response = await apiGet(pageParam ?? url);
  return response.data;
};

export const refreshSmartSummary = async (patientId) => {
  const url = `/api/patients/refresh_smart_summary/?patient_id=${patientId}`;
  const response = await apiPost(url);
  return response.data;
};
