import React, { useState } from "react";
import { editBoxStyles, saveCredentialFormStyles } from "./emrAuthStyle";
import { Box, Grid } from "@mui/material";
import Text from "components/common/Typography/Text";
import InputField from "components/common/FormComponents/InputField";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { emrCredentialsSchema } from "validations/Settings/emrCredentials.schema";
import Button from "components/common/Button/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  deleteCredentials,
  updateCredentials,
} from "apiClients/emrCredentials";
import EditIcon from "assets/svg/light/editIcon.js";
import DeleteIcon from "assets/svg/light/deleteIcon.js";

const CredentialItem = ({ credential }) => {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState("");

  const { isPending, mutate } = useMutation({
    mutationFn: async (data) => {
      if (data.delete) {
        await deleteCredentials(data.id);
      } else if (data.id) {
        await updateCredentials(data?.id, data);
      }
    },
    onSuccess: () => {
      setError("");
      queryClient.invalidateQueries({ queryKey: ["credentials"] });
    },
    onError: (error) => {
      setError(error?.response?.data?.message);
    },
  });

  const formik = useFormik({
    initialValues: {
      plugin_name: credential.plugin_name,
      username: credential.username,
      password: "",
    },
    enableReinitialize: true,
    validationSchema: emrCredentialsSchema,
    onSubmit: (values) => {
      mutate({ ...values, id: credential.id });
    },
  });

  return (
    <Grid item sm={6}>
      <Box sx={{ marginTop: "10px" }}>
        <Text variant="h3" marginY={1}>
          {credential.name ?? "Veradigm"}
        </Text>
      </Box>
      <Box sx={saveCredentialFormStyles}>
        <Box sx={{ display: "flex", flex: 1 }}>
          {isEditing ? (
            <InputField
              inputLabel={"Username"}
              required
              formik={formik}
              name="username"
            />
          ) : (
            <Box sx={editBoxStyles}>{credential.username}</Box>
          )}
        </Box>
        <Box sx={{ cursor: "pointer" }}>
          {isEditing ? (
            <CloseIcon fontSize="22px" onClick={() => setIsEditing(false)} />
          ) : (
            <Box sx={{ display: "flex" }}>
              <Box onClick={() => setIsEditing(true)}>
                <EditIcon />
              </Box>
              <Box
                sx={{ cursor: "pointer", marginLeft: "10px" }}
                onClick={() => mutate({ ...credential, delete: true })}>
                <DeleteIcon />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={saveCredentialFormStyles}>
        <Box sx={{ display: "flex", flex: 1 }}>
          {isEditing ? (
            <InputField
              inputLabel={"Password"}
              required
              formik={formik}
              name="password"
              type="password"
            />
          ) : (
            <Box sx={editBoxStyles}>*********</Box>
          )}
        </Box>
        <Box></Box>
      </Box>
      {isEditing && (
        <Box marginTop={2}>
          {error && (
            <Text marginY={1} variant="body2" color="red">
              {error}
            </Text>
          )}
          <Button
            text="verify"
            isLoading={isPending}
            disabled={!formik.values.username || !formik.values.password}
            onClick={formik.submitForm}
          />
        </Box>
      )}
    </Grid>
  );
};

export default CredentialItem;
