import { Button, useTheme } from "@mui/material";

export const IconButton = ({
  disabled = false,
  onClick,
  children,
  variant = "outlined",
  title,
}) => {
  const theme = useTheme();

  return (
    <Button
      disabled={disabled}
      title={title}
      onClick={onClick}
      sx={{
        width: "30px",
        height: "30px",
        minWidth: "unset",
        padding: "0",
        borderRadius: "10px",
        borderColor: theme.palette.common.grey,
      }}
      variant={variant}>
      {children}
    </Button>
  );
};
