import { LoadingButton } from "@mui/lab";
const Button = ({
  id = "",
  onClick,
  text,
  isLoading = false,
  disabled,
  leftSide = false,
  rightSide = false,
  sx = {},
  iconDetails = {
    allowIcon: false,
    icon: null,
    iconSx: { padding: "0px 5px 0px 5px", height: "28px", width: "28px" },
  },
  variant = "contained",
  type = "button",
  color = "primary",
}) => {
  let { allowIcon, icon, iconSx } = iconDetails;

  return (
    <LoadingButton
      data-testid={id}
      sx={{
        ...sx,
      }}
      title={text}
      onClick={onClick}
      loading={isLoading}
      disabled={disabled || isLoading}
      color={color}
      type={type}
      variant={variant}>
      {leftSide && allowIcon ? (
        <img alt={icon} src={icon} style={iconSx} />
      ) : (
        ""
      )}
      {text}
      {rightSide && allowIcon ? (
        <img alt={icon} src={icon} style={iconSx} />
      ) : (
        ""
      )}
    </LoadingButton>
  );
};
export default Button;
