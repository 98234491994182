import React from "react";

const ScheduleIcon = ({
  style = {},
  height = "32",
  width = "32",
  fill = "none",
}) => {
  return (
    <svg
      style={{ ...style }}
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32">
      <mask
        id="mask0_4093_1514"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32">
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4093_1514)">
        <path
          d="M16 24C14.7556 24 13.5667 24.1944 12.4333 24.5833C11.3 24.9722 10.2667 25.5555 9.33333 26.3333V26.6666H22.6667V26.3333C21.7333 25.5555 20.7 24.9722 19.5667 24.5833C18.4333 24.1944 17.2444 24 16 24ZM6.66667 25.1333C7.86667 23.9555 9.26111 23.0277 10.85 22.35C12.4389 21.6722 14.1556 21.3333 16 21.3333C17.8444 21.3333 19.5611 21.6722 21.15 22.35C22.7389 23.0277 24.1333 23.9555 25.3333 25.1333V7.99996H6.66667V25.1333ZM16 18.6666C14.7111 18.6666 13.6111 18.2111 12.7 17.3C11.7889 16.3888 11.3333 15.2888 11.3333 14C11.3333 12.7111 11.7889 11.6111 12.7 10.7C13.6111 9.78885 14.7111 9.33329 16 9.33329C17.2889 9.33329 18.3889 9.78885 19.3 10.7C20.2111 11.6111 20.6667 12.7111 20.6667 14C20.6667 15.2888 20.2111 16.3888 19.3 17.3C18.3889 18.2111 17.2889 18.6666 16 18.6666ZM16 16C16.5556 16 17.0278 15.8055 17.4167 15.4166C17.8056 15.0277 18 14.5555 18 14C18 13.4444 17.8056 12.9722 17.4167 12.5833C17.0278 12.1944 16.5556 12 16 12C15.4444 12 14.9722 12.1944 14.5833 12.5833C14.1944 12.9722 14 13.4444 14 14C14 14.5555 14.1944 15.0277 14.5833 15.4166C14.9722 15.8055 15.4444 16 16 16ZM6.66667 29.3333C5.93333 29.3333 5.30556 29.0722 4.78333 28.55C4.26111 28.0277 4 27.4 4 26.6666V7.99996C4 7.26662 4.26111 6.63885 4.78333 6.11663C5.30556 5.5944 5.93333 5.33329 6.66667 5.33329H8V2.66663H10.6667V5.33329H21.3333V2.66663H24V5.33329H25.3333C26.0667 5.33329 26.6944 5.5944 27.2167 6.11663C27.7389 6.63885 28 7.26662 28 7.99996V26.6666C28 27.4 27.7389 28.0277 27.2167 28.55C26.6944 29.0722 26.0667 29.3333 25.3333 29.3333H6.66667Z"
          fill="#2D3748"
        />
      </g>
    </svg>
  );
};

export default ScheduleIcon;
