import TabPill from "components/common/Tabs/TabPill";
import { useNavigate, useSearchParams } from "react-router-dom";
import MyTeam from "./MyTeam/MyTeam";
import PrivacySecurity from "./PrivacySecurity/PrivacySecurity";
import ProfileSettings from "./Profile/ProfileSettings";
import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Text from "components/common/Typography/Text";
import EHRAuthentication from "./EmrAuthentication/emrCrendentials";

const SettingsWrapper = () => {
  let [queryParameters] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  let tabsData = [
    {
      title: "Profile Settings",
      tab: <ProfileSettings />,
      value: 0,
    },
    {
      title: "My Team",
      tab: <MyTeam />,
      value: 1,
    },
    {
      title: "Privacy and Security",
      tab: <PrivacySecurity />,
      value: 2,
    },
    {
      title: "EMR Credentials",
      tab: <EHRAuthentication />,
      value: 3,
    },
  ];

  useEffect(() => {
    const tabValue = Number(queryParameters.get("tab"));
    if (tabValue >= 0 && tabValue < tabsData.length) {
      setTabIndex(tabValue);
    } else {
      setTabIndex(0);
    }
  }, [queryParameters]);

  const navigateToSettings = (newValue) => {
    navigate(`/settings?tab=${newValue}`);
  };

  return (
    <TabPill
      onTabChange={navigateToSettings}
      childrenArray={tabsData}
      defaultValue={tabIndex}
      tabSx={{ justifyContent: "flex-start" }}
    />
  );
};

export default SettingsWrapper;

export const SettingsTitle = ({ title, children }) => {
  return (
    <Box
      className="title-box"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}>
        <Text id={"settings-title-heading"} marginY={2} variant="h1">
          {title}
        </Text>
        <Grid container justifyContent="flex-end" width={"30%"}>
          {children}
        </Grid>
      </Box>
    </Box>
  );
};
