import MainLayout from "Layouts/AppContainer/Layout";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
const PrivateRoute = ({ theme }) => {
  const { loggedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  if (!loggedIn) {
    navigate("/login");
  }

  return (
    <MainLayout theme={theme}>
      <Outlet />
    </MainLayout>
  );
};

export default PrivateRoute;
