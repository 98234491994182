import { apiDelete, apiGet, apiPost, apiPut } from "./base";

export const fetchPharmacies = async (patientId, signal) => {
  const url = `/api/patients/${patientId}/pharmacy/`;
  const response = await apiGet(url, signal);
  return response.data;
};

export const fetchAllPharmacies = async (patientId) => {
  const url = `/api/patients/${patientId}/pharmacy/`;
  const response = await apiGet(url);

  let next = response.data?.next;

  while (next) {
    const nextResponse = await apiGet(next);
    response.data?.results.push(...nextResponse.data?.results);
    next = nextResponse.data?.next;
  }

  return response.data;
};

export const addPharmacy = async (patientId, data) => {
  const url = `/api/patients/${patientId}/pharmacy/`;
  const response = await apiPost(url, data);
  return response.data;
};

export const updatePharmacy = async (patientId, id, data) => {
  const url = `/api/patients/${patientId}/pharmacy/${id}/`;
  const response = await apiPut(url, data);
  return response.data;
};

export const deletePharmacy = async (patientId, id) => {
  const url = `/api/patients/${patientId}/pharmacy/${id}/`;
  const response = await apiDelete(url);
  return response.data;
};

export const searchPharmacies = async (
  patientId,
  query,
  zip_code,
  page,
  signal,
) => {
  const url = `/api/patients/${patientId}/pharmacy/search/?zip=${zip_code}&name=${query}&pagenumber=${page}`;
  const response = await apiGet(url, signal);
  return response.data;
};
