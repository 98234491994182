import moment from "moment";

// This should not be needed once the API always returns all dates in ISO-8601 format.
const possibleDateFormats = ["MM-DD-YYYY", "YYYY-MM-DD"];

export const Required = (value) => {
  if (!value) {
    return "This field is required";
  }
};

export const Max = (maxValue) => (value) => {
  // If working with dates we need to convert the value to a moment object to compare.
  const momentValue = moment(value, possibleDateFormats, true);
  if (momentValue.isValid()) {
    if (momentValue.isAfter(moment(maxValue, possibleDateFormats, true))) {
      return `The value must be less than ${maxValue}`;
    }

    return;
  }

  if (value && value > maxValue) {
    return `The value must be less than ${maxValue}`;
  }
};

export const Min = (minValue) => (value) => {
  // If working with dates we need to convert the value to a moment object to compare.
  const momentValue = moment(value, possibleDateFormats, true);
  if (momentValue.isValid()) {
    if (momentValue.isBefore(moment(minValue, possibleDateFormats, true))) {
      return `The value must be greater than ${minValue}`;
    }

    return;
  }
  if (value && value < minValue) {
    return `The value must be greater than ${minValue}`;
  }
};

export const MaxLength = (maxLength) => (value) => {
  if (value && value.length > maxLength) {
    return `Ensure this field has no more than ${maxLength} characters`;
  }
};

export const Matches = (regex, message) => (value) => {
  if (value && !regex.test(value)) {
    return message;
  }
};
