import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AISparkImage from "assets/svg/colored/ai_sparkle_black.svg";
import Text from "components/common/Typography/Text";

const useStyles = makeStyles((theme) => ({
  loader: {
    border: `8px solid ${theme.palette.common.lightGrey}`,
    borderTop: `8px solid ${theme.palette.common.purple}`,
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

export const SmartSummaryLoader = ({ text, lowerText }) => {
  const classes = useStyles();
  return (
    <>
      <Box position={"relative"}>
        <Grid container spacing={2}>
          <div
            style={{
              minHeight: "40vh",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div style={{ position: "relative", marginBottom: "80px" }}>
              <div className={classes.loader}></div>
              <img
                alt="AI Spark"
                src={AISparkImage}
                style={{
                  position: "absolute",
                  top: "31%",
                  left: "29%",
                  height: "34px",
                  width: "34px",
                }}
              />
            </div>
            <div>
              <Text variant="bodyL" fontWeight={700}>
                {text}
              </Text>
            </div>
            <div>
              <Text variant="bodyM">{lowerText}</Text>
            </div>
          </div>
        </Grid>
      </Box>
    </>
  );
};
