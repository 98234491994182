import { Box, useTheme } from "@mui/material";
import Text from "components/common/Typography/Text";
import Button from "components/common/Button/Button";
import Editor from "components/Editor/Editor";

export const PastVisitTab = ({
  setOtherEncounter,
  calledFromAppts,
  setComponentName,
  data,
}) => {
  return (
    <>
      <Box sx={{ display: "flex", gap: 4 }}>
        <Box>
          <Box>
            <Box>
              <Text variant="propertyLabel">Created by</Text>
            </Box>
            <Text variant="bodyXs">
              {data?.user_name ? data?.user_name : "---"}
            </Text>
          </Box>
        </Box>
        {/* NOTE -> designs mark this section as being removed */}
        <Box>
          <Box>
            <Text variant="propertyLabel">Reason for Visit</Text>
          </Box>
          <Text variant="bodyXs">
            {" "}
            {data?.visit_reason ? data?.visit_reason : "---"}{" "}
          </Text>
        </Box>
        {/* End of NOTE */}
      </Box>
      <Box paddingY={1}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Text variant="h2">Soap Notes</Text>
          {!calledFromAppts &&
          data?.sign_close_ind !== "c" &&
          data?.smart_note_data !== null ? (
            <Button
              text="Review Notes"
              sx={{ marginTop: "10px", height: "30px" }}
              onClick={() => {
                setOtherEncounter(data);
                setComponentName("endVisit");
              }}
            />
          ) : null}
        </Box>
        <AccordionNotes
          marginTop="20px"
          markDownFormat={data?.plan_note ? true : false}
          notes={data?.plan_note ? data?.plan_note : data?.soap_notes}
        />
      </Box>
    </>
  );
};

const AccordionNotes = ({ notes = null, marginTop, markDownFormat }) => {
  const theme = useTheme();
  return (
    <Box
      className="cus-textFocus"
      sx={{
        borderRadius: "11px",
        background: theme.palette.common.white,
        marginTop: marginTop,
      }}>
      {notes == "" || notes == null ? (
        <Box marginY={4}>
          <Text variant="bodyS">No Data Found.</Text>
        </Box>
      ) : markDownFormat ? (
        <div
          style={{
            fontSize: "14px",
            padding: "5px",
            background: theme.palette.common.white,
            borderRadius: "10px",
            maxHeight: "38vh",
            overflow: "auto",
          }}>
          <Editor data={notes} editable={false} />
        </div>
      ) : (
        <div
          style={{
            fontSize: "14px",
            padding: "5px",
            border: "1px solid #ccc",
            background: "#f2f2f2",
            borderRadius: "10px",
            maxHeight: "38vh",
            overflow: "auto",
          }}
          dangerouslySetInnerHTML={{ __html: notes }}
        />
      )}
    </Box>
  );
};
