import { memo, useContext, useEffect, useState } from "react";
import { SubForm } from "../common/SubForm";
import Text from "components/common/Typography/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addMedicalEncounter,
  deleteMedicalEncounter,
  fetchAllMedicalEncounters,
  updateMedicalEncounter,
} from "apiClients/medicalEncounters";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { Max, Min, Required } from "validations/validators";
import moment from "moment";
import { FormContext } from "components/common/FormComponents/Form";
import { dateFormat } from "utils/date";
import { formatErrorsToAlerts } from "../common/utils";

const EncounterType = Object.freeze({
  HOSPITAL: "Hospital",
  ER: "Emergency Room",
  URGENT_CARE: "Urgent Care",
  OTHER: "Other",
});

export const RecentMedicalEncounters = memo(({ errors }) => {
  const { getFormValue } = useContext(FormContext);
  const queryClient = useQueryClient();
  const [values, setValues] = useState([]);
  const { registerForm, patientId } = useContext(IntakeFormContext);

  const inputs = [
    {
      label: "Type of encounter",
      key: "encounter_type",
      type: "select",
      options: [
        { value: EncounterType.HOSPITAL, label: EncounterType.HOSPITAL },
        { value: EncounterType.ER, label: EncounterType.ER },
        { value: EncounterType.URGENT_CARE, label: EncounterType.URGENT_CARE },
        { value: EncounterType.OTHER, label: EncounterType.OTHER },
      ],
      cols: 2,
      validators: [Required],
    },
    {
      label: "Medical facility",
      key: "name",
      cols: 3,
    },
    {
      label: "Reason",
      key: "reason",
      placeholder: "Enter Reason",
      cols: 3,
    },
    {
      label: "Date of admission",
      key: "date",
      type: "date",
      cols: 2,
      max: moment().format(dateFormat),
      validators: [Required],
      renderOnChanged: true,
      getDynamicValidators: (formObjectName) => {
        return [
          (value) => {
            const medicalEncounter = getFormValue(formObjectName);
            if (!!medicalEncounter.end_date) {
              return Max(medicalEncounter.end_date)(value);
            }
            return undefined;
          },
        ];
      },
    },
    {
      label: "Date of discharge",
      key: "end_date",
      type: "date",
      cols: 2,
      renderOnChanged: true,
      getDynamicValidators: (formObjectName) => {
        return [
          (value) => {
            const medicalEncounter = getFormValue(formObjectName);
            if (!!medicalEncounter.date) {
              return Min(medicalEncounter.date)(value);
            }
            return undefined;
          },
        ];
      },
    },
  ];

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      if (dirtyValue.id) {
        if (dirtyValue.delete) {
          // If delete is set we need to delete the medical encounter.
          return deleteMedicalEncounter(patientId, dirtyValue.id);
        } else {
          // If no delete is set, we need to update the medical encounter since it is dirty (changed).
          return updateMedicalEncounter(patientId, dirtyValue.id, dirtyValue);
        }
      } else {
        // If no ID is present, it is a new medical encounter and we need to add it.
        return addMedicalEncounter(patientId, dirtyValue);
      }
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["medicalEncounters", patientId],
      });
    },
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ["medicalEncounters", patientId],
      });
    };
  }, [queryClient, patientId]);

  const { data, isLoading } = useQuery({
    queryKey: ["medicalEncounters", patientId],
    queryFn: () => fetchAllMedicalEncounters(patientId),
  });

  const handleSubmit = (dirtyValues) => {
    const operations = dirtyValues.map((value) => {
      const mapped = {
        ...value,
        // Because of the validations on the backend, we need to format the dates to the this format.
        date: moment(value.date).format("YYYY-MM-DD"),
        // If the end_date is not set, we need to set it to null because "" does not validate with the backend.
        end_date: value.end_date
          ? moment(value.end_date).format("YYYY-MM-DD")
          : null,
      };
      return mutation.mutateAsync(mapped);
    });
    return Promise.allSettled(operations);
  };

  useEffect(() => {
    if (data?.results) {
      const mapped = data.results.map((result) => {
        return {
          ...result,
          // Make sure the date is existing or a string so the input is controlled.
          end_date: result.end_date ?? "",
        };
      });
      setValues(mapped);
      registerForm("medicalEncounters", handleSubmit, mapped);
    }
  }, [data]);

  return (
    <>
      <Text variant="h1">Recent Encounters</Text>
      <SubForm
        dataLoading={isLoading}
        formKey={"medicalEncounters"}
        subSections={inputs}
        data={values}
      />
      {formatErrorsToAlerts(errors)}
    </>
  );
});
