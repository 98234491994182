import { Box, Grid } from "@mui/material";
import CardsContainer from "Layouts/CardsContainer";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { alignItemsCenter, flexColumn } from "styles";
import UpperHeader from "./components/UpperHeader";
import { dateFormat } from "utils/date";

const AppointmentsDashboard = () => {
  const containerRef = useRef(null);
  const [isVerticalView, setIsVerticalView] = useState(false);
  const [date, setDate] = useState(
    localStorage.getItem("scheduledPatientDate") ?? moment().format(dateFormat),
  );
  const [patientsData, setPatientsData] = useState([]);
  const [filteredPatientsData, setFilteredPatientsData] =
    useState(patientsData);
  const [page, setPage] = useState(1);

  let mainDivStyle = {
    marginTop: "0px",
    ...flexColumn,
    ...alignItemsCenter,
    minHeight: "auto",
    borderRadius: "24px",
    background: "#FFFFFFBF",
    padding: "20px",
  };

  useEffect(() => {
    if (containerRef.current) {
      const height = window.innerHeight - containerRef.current.offsetTop;
      containerRef.current.style.height = `${height}px`;
    }
  }, [isVerticalView]);

  useEffect(() => {
    date && localStorage.setItem("scheduledPatientDate", date);
  }, [date]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} xl={12} xxl={12} md={12} sm={12} xs={12}>
        <UpperHeader
          isVerticalView={isVerticalView}
          setIsVerticalView={setIsVerticalView}
          date={date}
          setDate={setDate}
        />
        <Box sx={mainDivStyle}>
          <CardsContainer
            date={date}
            page={page}
            setPage={setPage}
            patientsData={patientsData}
            isVerticalView={isVerticalView}
            setPatientsData={setPatientsData}
            setIsVerticalView={setIsVerticalView}
            filteredPatientsData={filteredPatientsData}
            setFilteredPatientsData={setFilteredPatientsData}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export { AppointmentsDashboard };
