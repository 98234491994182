import { Box, Grid } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminInfo, updateAdminInfo } from "redux/features/Admin/AdminSlice";
import { getUsersList } from "redux/features/MyTeam/myTeamSlice";
import Button from "../../../../components/common/Button/Button";
import { ImageUploader } from "../../../../components/common/ImageUploader";
import InputField from "../../../../components/common/FormComponents/InputField";
import SelectField from "../../../../components/common/FormComponents/SelectField";
import CustomModal from "../../../../components/common/Modal/CustomModal";
import Text from "../../../../components/common/Typography/Text";
import { titleOptions } from "../Profile/utils";
import { dateFormat } from "utils/date";
import { ProfileInputs } from "../common";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { Form } from "components/common/FormComponents/Form";
import { DatePickerInput } from "components/common/FormComponents/DatePickerInput";

const EditTeamMemberModal = ({ open, setOpen, userId = null }) => {
  const dispatch = useDispatch();
  const { account_id } = useSelector((state) => state.auth);
  const {
    isAdminLoading,
    isAdminUpdateLoading,
    adminData,
    adminUpdateSuccess,
  } = useSelector((state) => state.admin);
  const [photoFile, setPhotoFile] = useState(null);

  const initializeFormValues = () => {
    return {
      title: adminData?.title
        ? titleOptions?.find(({ value }) => value === adminData?.title)?.label
        : "",
      first_name: adminData?.id ? adminData?.first_name : "",
      last_name: adminData?.id ? adminData?.last_name : "",
      username: adminData?.id ? adminData?.username : "",
      birthdate: adminData?.birthdate
        ? moment(adminData?.birthdate).format(dateFormat)
        : "",
      national_provider_identifier: adminData?.id
        ? adminData?.national_provider_identifier
        : "",
      email: adminData?.id ? adminData?.email : "",
      phone_number: adminData?.id ? adminData?.phone_number : "",
      photo: adminData?.id ? adminData?.photo : "",
    };
  };

  const handleSubmit = (data) => {
    let formData = new FormData();
    formData.append("title", data?.title?.value ?? data?.title);
    formData.append("first_name", data?.first_name);
    formData.append("last_name", data?.last_name);
    formData.append("username", data?.username);
    // temporary until we can pass ISO dates to API.
    // Must be in YYYY-MM-DD format for API to accept.
    formData.append(
      "birthdate",
      moment(data?.birthdate, dateFormat).format("YYYY-MM-DD"),
    );
    formData.append(
      "national_provider_identifier",
      data?.national_provider_identifier,
    );
    formData.append("email", data?.email);
    formData.append("phone_number", data?.phone_number);
    !!photoFile && formData.append("photo", photoFile);
    dispatch(updateAdminInfo(userId, formData));
  };

  useEffect(() => {
    if (userId) {
      dispatch(getAdminInfo(userId));
    }

    if (adminUpdateSuccess) {
      dispatch(getUsersList(account_id));
      setOpen && setOpen(false);
    }
  }, [dispatch, userId, adminUpdateSuccess, account_id, setOpen]);

  function onImageChange(file) {
    setPhotoFile(file);
  }

  return (
    <CustomModal
      setOpen={() => {
        setOpen && setOpen(false);
      }}
      open={open}
      title={"Edit Team Member"}
      width="auto"
      maxWidth={"md"}
      fullWidth={true}>
      <Form initialValues={initializeFormValues()} onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <ImageUploader
            onChange={onImageChange}
            name="photo"
            inForm={false}
            value={adminData?.photo}
          />
        </Box>
        <Grid container spacing={2}>
          <SelectField
            key={adminData?.title}
            options={titleOptions}
            name="title"
            label={"Title"}
            value={
              adminData?.title
                ? titleOptions?.find(({ value }) => value === adminData?.title)
                : titleOptions[0]
            }
            cols={6}
            placeholder="Select One"
          />
          <SkeletonWrapper
            condition={!isAdminLoading}
            multipleCount={6}
            props={[{ width: "40%", height: "60px", display: "flex" }]}>
            {ProfileInputs.map((item, i) =>
              item?.type === "text" ? (
                <InputField
                  key={i}
                  {...(item.type === "email" && { disabled: true })}
                  name={item.name}
                  type={item.type}
                  label={item.label}
                  cols={item.cols}
                  placeholder={item?.placeHolder}
                  value={adminData?.[item.name] ?? ""}
                  validators={item.validators}
                />
              ) : (
                <Fragment key={i}>
                  <DatePickerInput
                    label={item.label}
                    cols={item.cols}
                    name={item.name}
                    value={adminData?.[item.name] ?? ""}
                    validators={item.validators}
                  />
                </Fragment>
              ),
            )}
          </SkeletonWrapper>
          <Grid item>
            <Text variant="h4" sx={{ marginTop: "10px" }}>
              Email :
            </Text>
            <Text variant="h4" sx={{ marginTop: "15px" }}>
              {adminData?.email ?? ""}
            </Text>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            padding: "25px 0px 0px 10px",
            justifyContent: "center",
          }}>
          <Button
            id="edit-Cancel-btn"
            onClick={() => {
              setOpen(false);
            }}
            text="Cancel"
            variant="outlined"
            color="tertiary"
            sx={{
              width: "150px",
            }}
          />
          <Button
            id="edit-team-member-id"
            text="Save"
            isLoading={isAdminUpdateLoading}
            type="submit"
            sx={{
              width: "150px",
              marginLeft: "15px",
            }}
          />
        </Box>
      </Form>
    </CustomModal>
  );
};

export default EditTeamMemberModal;
