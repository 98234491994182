import { Grid } from "@mui/material";
import InputField from "components/common/FormComponents/InputField";
import { Fragment, memo, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IntakeFormContext } from "./IntakeFormProvider";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { RadioField } from "components/common/FormComponents/RadioField";
import SelectField from "components/common/FormComponents/SelectField";
import Button from "components/common/Button/Button";
import { FormContext } from "components/common/FormComponents/Form";
import { DatePickerInput } from "components/common/FormComponents/DatePickerInput";
import { Trash } from "components/common/SVG/Trash";
import { IconButton } from "components/common/Button/IconButton";

// This is a generic sub form that can be used to create a form with a list of inputs.
export const SubForm = memo(
  ({
    subSections = [],
    cols = 11,
    formKey = "",
    dataLoading = false,
    data = [],
    onAdd,
    // Disables the add button.
    addDisabled = false,
  }) => {
    const { getFormValue } = useContext(FormContext);
    const { patientId } = useContext(IntakeFormContext);
    const [_, forceRender] = useState(false);
    const { account_id } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const defaultValue = subSections.reduce(
      (acc, section) => {
        acc[section.key] =
          section.type === "select" ? section.options[0].value : "";
        return acc;
      },
      {
        account_id,
        patient_id: patientId,
      },
    );

    let [formValue, setFormValue] = useState([]);

    useEffect(() => {
      if (data.length) {
        setFormValue(data);
      }
    }, [data]);

    useEffect(() => {
      setTimeout(() => {
        // Wait for one event loop to complete before toggling the loading state.
        // This will give large forms a chance to render before the loading state is toggled off.
        setIsLoading(dataLoading);
      });
    }, [dataLoading]);

    const onDelete = (val, index) => {
      if (!val.id) {
        // If the value does not have an ID, it means it is a new value not saved to the DB and we can just remove it from the form.
        setFormValue(formValue.filter((_, i) => i !== index));
        return;
      }

      setFormValue(
        formValue.map((val, i) =>
          i === index ? { ...val, delete: true } : val,
        ),
      );
    };

    const _onAdd = () => {
      if (onAdd) {
        onAdd();
        return;
      }

      setFormValue((prev) => [...prev, defaultValue]);
    };

    // Since some of the dates in the form are dependent on each other, we need to force a re-render when a date is changed.
    // We could improve this to only re-render the dependent inputs in the future.
    const handleDateChange = (subSection) => {
      if (subSection.renderOnChanged) {
        // Give the form a chance to update before forcing a re-render.
        setTimeout(() => forceRender((prev) => !prev));
      }
    };

    return (
      <section style={{ margin: "1rem 0" }}>
        {formValue?.map((val, index) => {
          // If the value has a delete key, then we still want to render some inputs but hide them. This so the HTML form can still submit the ID and delete key.
          if (val.delete)
            return (
              <Fragment key={index}>
                <input
                  type="hidden"
                  name={`${formKey}[${index}][id]`}
                  value={val.id}
                />
                <input
                  type="hidden"
                  name={`${formKey}[${index}][delete]`}
                  value={true}
                />
              </Fragment>
            );
          return (
            <Fragment key={index}>
              <Grid container sx={{ my: 4 }}>
                <Grid item xs={cols}>
                  <Grid container spacing={2}>
                    {subSections.map((subSection) => {
                      const formName = `${formKey}[${index}][${subSection.key}]`;
                      const formObjectName = `${formKey}[${index}]`;
                      const renderKey = `${subSection.key}-${index}`;
                      const value = getFormValue(
                        formName,
                        subSection.options,
                        formKey,
                        formValue,
                      );
                      let validators = subSection.validators || [];
                      if (subSection.getDynamicValidators) {
                        // Dynamic validators are used to create validators that depend on other form values.
                        const specialValidators =
                          subSection.getDynamicValidators(formObjectName);

                        validators = [...validators, ...specialValidators];
                      }
                      if (subSection.customComponent) {
                        return subSection.customComponent({
                          key: renderKey,
                          name: formName,
                          patientId,
                          value,
                        });
                      }
                      if (subSection.type === "radio") {
                        return (
                          <RadioField
                            key={renderKey}
                            name={formName}
                            cols={subSection.cols ?? 6}
                            options={subSection.radioOptions}
                            value={value}
                            validators={validators}
                          />
                        );
                      }

                      if (subSection.type === "select") {
                        return (
                          <SelectField
                            key={renderKey}
                            name={formName}
                            label={subSection.label}
                            placeholder={
                              subSection.placeholder || subSection.label
                            }
                            freeSolo={subSection.freeSolo}
                            cols={subSection.cols ?? 6}
                            options={subSection.options}
                            disableClearable={true}
                            value={value}
                            validators={validators}
                          />
                        );
                      }

                      if (subSection.type === "spacer") {
                        return (
                          <Grid item xs={subSection.cols} key={renderKey} />
                        );
                      }

                      if (subSection.type === "date") {
                        return (
                          <DatePickerInput
                            key={renderKey}
                            label={subSection.label}
                            cols={subSection.cols}
                            name={formName}
                            value={value}
                            validators={validators}
                            onChange={() => handleDateChange(subSection)}
                          />
                        );
                      }

                      return (
                        <InputField
                          name={formName}
                          label={subSection.label}
                          cols={subSection.cols ?? 6}
                          key={renderKey}
                          max={subSection.max}
                          readonly={subSection.readonly}
                          placeholder={
                            subSection.placeholder || subSection.label
                          }
                          value={value}
                          validators={validators}
                          copyable={subSection.copyable}
                        />
                      );
                    })}
                    <input
                      type="hidden"
                      name={`${formKey}[${index}][id]`}
                      value={val.id}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={1}
                  sx={{
                    position: "relative",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    bottom: "4px",
                  }}>
                  <IconButton
                    variant="outlined"
                    onClick={() => onDelete(val, index)}>
                    <Trash />
                  </IconButton>
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
        <SkeletonWrapper
          condition={!isLoading}
          props={[{ width: "100%", height: "40px" }]}>
          <Button
            text="+ Add"
            variant="text"
            sx={{ justifyContent: "flex-start" }}
            disabled={addDisabled}
            onClick={_onAdd}
          />
        </SkeletonWrapper>
      </section>
    );
  },
);
