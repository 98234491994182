import { Alert } from "@mui/material";
import Text from "components/common/Typography/Text";
import { Fragment } from "react";

// Given an object of values and an object of initial values, return an object of dirty values
// Uses JSON.stringify to compare values to cover multiple data types
export function getDirtyValues(values, initialValues, mappers) {
  const dirtyValues = {};
  for (const key in initialValues) {
    // If a mapper exists for the key, use it to map the values back to the API format.
    const mapFn = mappers[key];
    let valuesToCheck = values[key];

    // If a mapper exists, use it to map the value back to the API format.
    if (mapFn) {
      valuesToCheck = mapFn(values[key]);
    }

    if (Array.isArray(valuesToCheck)) {
      // If the field is an array then loop through the array and compare each value with the initial value.
      dirtyValues[key] = valuesToCheck.filter((valueToCheck) => {
        const matchingValue = initialValues[key]?.find(
          (x) => x.id?.toString() === valueToCheck.id,
        );

        if (matchingValue) {
          return Object.entries(valueToCheck).some(([key, value]) => {
            return value?.toString() !== matchingValue[key]?.toString();
          });
        }

        return true;
      });
    } else {
      if (
        JSON.stringify(valuesToCheck) !== JSON.stringify(initialValues[key])
      ) {
        dirtyValues[key] = valuesToCheck;
      }
    }
  }
  return dirtyValues;
}

export function formatErrorsToAlerts(errors = []) {
  const getErrorContent = (error, idx) => {
    const internalError = (
      <Text color="white" variant="bodyS">
        Internal Error Occurred
      </Text>
    );

    if (!error?.reason?.response) {
      return internalError;
    } else {
      const response = error?.reason?.response;

      // If the response is a string then we didn't get the expected error JSON.
      if (typeof response.data === "string") {
        return internalError;
      }

      return (
        <Fragment key={idx}>
          <ul>
            {response &&
              Object.entries(response.data).length > 0 &&
              Object.entries(response.data).map(([errorKey, errorMessage]) => (
                <li key={errorKey}>
                  {errorKey}: {errorMessage}
                </li>
              ))}
          </ul>
        </Fragment>
      );
    }
  };
  // Takes each error in the object and create a Mui alert for each one
  return errors.map((error, idx) => {
    return (
      <Alert variant="filled" key={idx} severity="error" sx={{ my: 2 }}>
        {getErrorContent(error, idx)}
      </Alert>
    );
  });
}
