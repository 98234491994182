import { Box, Divider, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AISparkImage from "assets/svg/colored/ai_sparkle_black.svg";
import Text from "components/common/Typography/Text";
import { forwardRef, useEffect, useState } from "react";
import Button from "components/common/Button/Button";
import EndVisit from "./EndVisit";
import TabPill from "components/common/Tabs/TabPill";
import rightArrow from "assets/svg/light/rightArrow.svg";
import leftArrow from "assets/svg/colored/leftArrowBlue.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getEncounterById } from "redux/features/Encounter/EncounterSlice";
import {
  PatientDetailHeader,
  PatientDetailWrapper,
} from "components/common/PatientDetailWrapper";

const useStyles = makeStyles(() => ({
  dashedBorderChip: {
    border: "1px dashed #7119FF !important",
  },
  loader: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    border: "8px solid #7119FF",
    borderBottom: "8px solid transparent",
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

const EndVisitStepper = forwardRef(
  ({ isPostCalled, encounter, sendEncounter, setComponentName }, ref) => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [endVisitActive, setEndVisitActive] = useState(0);
    const [reviewLater, setReviewLater] = useState(false);

    const { encounterSuccess, isEncounterLoading } = useSelector(
      (state) => state.encounter,
    );

    const { processingStatus, recordingIsLoading } = useSelector(
      (state) => state.recording,
    );

    function tabOverFlowDetails() {
      setEndVisitActive((prev) => (prev === 0 ? 1 : 0));
    }

    // Poll encounter data every 5 seconds until smart_note_data is available
    useEffect(() => {
      if (!encounter?.smart_note_data && processingStatus !== "FAILED") {
        const timeout = setTimeout(() => {
          dispatch(getEncounterById(encounter.id));
        }, 15000);

        return () => clearTimeout(timeout);
      }
    }, [encounter]);

    function retryEncounter() {
      dispatch(getEncounterById(encounter.id));
    }

    let tabsData = [
      {
        title: "Encounter details",
        tab: <>This is just for tab</>,
        value: 0,
      },
      {
        title: "Note Review",
        tab: (
          <EndVisit
            ref={ref}
            encounter={encounter}
            isPostCalled={isPostCalled}
            sendEncounter={sendEncounter}
          />
        ),
        value: 1,
      },
    ];

    function sendEncounterForReviewLater() {
      setReviewLater(true);
      sendEncounter(ref.current.getPlanNotesInMarkdownFormat(), "o");
    }

    function cancelEndVisit() {
      setComponentName(null);
    }

    useEffect(() => {
      if (encounterSuccess && reviewLater) {
        navigate("/patients");
      }
    }, [encounterSuccess]);

    if (
      !encounter?.smart_note_data ||
      !encounter?.plan_note ||
      recordingIsLoading
    ) {
      return (
        <Box
          sx={{
            borderRadius: "24px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            height: "72vh",
            marginTop: "3px",
            background: theme.palette.background.paper,
          }}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Box sx={{ position: "relative" }}>
              <Box className={classes.loader}></Box>
              <img
                alt="AI Spark"
                src={AISparkImage}
                style={{
                  position: "absolute",
                  top: "31%",
                  left: "29%",
                  height: "34px",
                  width: "34px",
                }}
              />
            </Box>
            <Box sx={{ paddingTop: "24px" }}>
              {processingStatus === "FAILED" ? (
                <Text variant="h2" sx={{ color: theme.palette.error.light }}>
                  Unable To Process Smart Assessment{" "}
                </Text>
              ) : (
                <Text variant="h2">Generating Your Smart Assessment </Text>
              )}
            </Box>
            <Box sx={{ paddingTop: "10px" }}>
              <Text variant="bodyM">
                {processingStatus === "FAILED"
                  ? "Click “Retry” to re-process the recording."
                  : "Estimated Time: 60 Sec."}{" "}
              </Text>
            </Box>
            <Box sx={{ paddingTop: "10px" }}>
              <Button
                text={processingStatus === "FAILED" ? "Retry" : "Cancel"}
                variant="outlined"
                sx={{
                  border: `1px solid ${theme.palette.common.middleLightGrey}`,
                }}
                onClick={
                  processingStatus === "FAILED"
                    ? retryEncounter
                    : cancelEndVisit
                }
              />
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <PatientDetailWrapper>
        <Box
          sx={{
            height: "68vh",
          }}>
          <PatientDetailHeader title="Finalize Assessment & Plan Note">
            {endVisitActive === 0 ? (
              <Button
                text="Next"
                rightSide
                sx={{ padding: "5px" }}
                iconDetails={{ allowIcon: true, icon: rightArrow }}
                onClick={() => {
                  setEndVisitActive(1);
                }}
              />
            ) : (
              <>
                <Button
                  leftSide
                  text="Back"
                  variant="outlined"
                  iconDetails={{ allowIcon: true, icon: leftArrow }}
                  sx={{ padding: "5px", marginLeft: "10px" }}
                  onClick={() => {
                    setEndVisitActive(0);
                  }}
                />
                <Button
                  text="Review Later"
                  variant="outlined"
                  sx={{ padding: "5px 10px", marginLeft: "10px" }}
                  isLoading={isEncounterLoading}
                  onClick={sendEncounterForReviewLater}
                />
                <Button
                  sx={{ padding: "5px 15px", marginLeft: "10px" }}
                  text="Sign Notes & End Visit"
                />
              </>
            )}
          </PatientDetailHeader>
          <Box sx={{ padding: "10px 15px" }}>
            <TabPill
              showBorder={false}
              numberedTabs={true}
              multipleTitles={true}
              childrenArray={tabsData}
              defaultValue={endVisitActive}
              componentTitle={["", "NOTES"]}
              onTabChange={tabOverFlowDetails}
              tabTitle={{
                title: "REVIEW RECORDING ITEMS",
                tabTitleSx: {
                  color: theme.palette.common.grey,
                },
              }}
              tabOverFlowDetails={{ marginTop: "200px" }}
              tabSx={{ fontWeight: "700", justifyContent: "flex-start" }}
            />
          </Box>
        </Box>
      </PatientDetailWrapper>
    );
  },
);

export default EndVisitStepper;
