import { Box, Grid } from "@mui/material";
import Button from "components/common/Button/Button";
import { ImageUploader } from "components/common/ImageUploader";
import { DatePickerInput } from "components/common/FormComponents/DatePickerInput";
import { Form } from "components/common/FormComponents/Form";
import InputField from "components/common/FormComponents/InputField";
import SelectField from "components/common/FormComponents/SelectField";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import moment from "moment/moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAdminStatuses,
  getAdminInfo,
  updateAdminInfo,
} from "redux/features/Admin/AdminSlice";
import { getAccountID } from "redux/features/Auth/loginSlice";
import { setActiveLocation } from "redux/features/Locations/locationsSlice";
import { getUsersList } from "redux/features/MyTeam/myTeamSlice";
import { getGroupsOptions } from "redux/features/common/optionSlice";
import { flexRow, flexWrap } from "styles";
import { dateFormat } from "utils/date";
import { titleOptions } from "./utils";
import { SettingsTitle } from "../Wrapper";
import { ProfileInputs } from "../common";

const ProfileSettings = () => {
  const dispatch = useDispatch();

  const [photoFile, setPhotoFile] = useState(null);
  const { userId } = useSelector((state) => state.auth);
  const { account_id } = useSelector((state) => state.auth);
  const {
    isAdminLoading,
    isAdminUpdateLoading,
    adminData,
    adminUpdateSuccess,
  } = useSelector((state) => state.admin);
  const { locations, isLocationsLoading, activeLocation } = useSelector(
    (state) => state.locations,
  );

  const initialFormValues = {
    title: adminData?.title
      ? titleOptions?.find(({ value }) => value === adminData?.title)?.title
      : titleOptions[0]?.title,
    first_name: adminData?.first_name ?? "",
    last_name: adminData?.last_name ?? "",
    username: adminData?.username ?? "",
    birthdate: adminData?.birthdate
      ? moment(adminData?.birthdate).format(dateFormat)
      : "",
    national_provider_identifier: adminData?.national_provider_identifier ?? "",
    email: adminData?.email ?? "",
    phone_number: adminData?.phone_number ?? "",
    photo: adminData?.photo ?? "",
  };

  useEffect(() => {
    userId && dispatch(getAdminInfo(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (adminUpdateSuccess) {
      dispatch(getAdminInfo(userId));
      dispatch(getAccountID());
    }
  }, [dispatch, userId, adminUpdateSuccess]);

  const handleSubmit = (data) => {
    let formData = new FormData();
    formData.append("title", data?.title?.value ?? data?.title);
    formData.append("first_name", data?.first_name);
    formData.append("last_name", data?.last_name);
    formData.append("username", data?.username);
    // temporary until we can pass ISO dates to API.
    // Must be in YYYY-MM-DD format for API to accept.
    formData.append(
      "birthdate",
      moment(data?.birthdate, dateFormat).format("YYYY-MM-DD"),
    );
    formData.append(
      "national_provider_identifier",
      data?.national_provider_identifier,
    );
    formData.append("email", data?.email);
    formData.append("phone_number", data?.phone_number);
    !!photoFile && formData.append("photo", photoFile);
    dispatch(updateAdminInfo(userId, formData));
  };

  useEffect(() => {
    return () => dispatch(clearAdminStatuses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAccountID());
    dispatch(getGroupsOptions());
  }, [dispatch]);

  useEffect(() => {
    account_id && dispatch(getUsersList(account_id));
  }, [dispatch, account_id]);

  function setLocation(_, v) {
    // setting active location id is store.
    dispatch(setActiveLocation(v));
  }

  function onImageChange(file) {
    setPhotoFile(file);
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={initialFormValues}>
      <SettingsTitle title="Profile Settings">
        <SelectField
          required
          cols={12}
          name={"Locations"}
          options={locations}
          disableClearable={true}
          defaultValue={activeLocation}
          loading={isLocationsLoading}
          onChange={setLocation}
          inputLabel={"Locations"}
        />
      </SettingsTitle>
      <Box sx={{ ...flexWrap, ...flexRow, gap: "1rem" }}>
        <SkeletonWrapper
          condition={!isAdminLoading}
          multipleCount={6}
          props={[{ width: "49%", height: "60px", display: "flex" }]}>
          {adminData && (
            <Grid container spacing={2}>
              <SelectField
                key={adminData?.title}
                name={"title"}
                options={titleOptions}
                value={
                  adminData?.title
                    ? titleOptions?.find(
                        ({ value }) => value === adminData?.title,
                      )
                    : titleOptions[0]
                }
                label={"Title"}
                cols={6}
              />
              <Grid item xs={6} md={6} sm={6} lg={6}>
                <ImageUploader
                  name="photo"
                  onChange={onImageChange}
                  value={adminData?.photo}
                />
              </Grid>

              {ProfileInputs.map((item, i) =>
                item?.type === "text" ? (
                  <InputField
                    key={i}
                    {...(item.type === "email" && { disabled: true })}
                    name={item.name}
                    type={item.type}
                    label={item.label}
                    cols={item.cols}
                    placeholder={item?.placeHolder}
                    value={adminData?.[item.name] ?? ""}
                    validators={item.validators}
                  />
                ) : (
                  <Fragment key={i}>
                    <DatePickerInput
                      label={item.label}
                      cols={item.cols}
                      name={item.name}
                      value={adminData?.[item.name] ?? ""}
                      validators={item.validators}
                    />
                  </Fragment>
                ),
              )}
              <Grid item>
                <Text variant="h4" sx={{ marginTop: "10px" }}>
                  Email :
                </Text>
                <Text variant="h4" sx={{ marginTop: "10px" }}>
                  {adminData?.email ?? ""}
                </Text>
              </Grid>

              <Grid item xs={12} md={12} sm={12} lg={12}>
                <Button
                  sx={{ marginTop: "15px" }}
                  isLoading={isAdminUpdateLoading}
                  text="Update Profile"
                  type="submit"
                />
              </Grid>
            </Grid>
          )}
        </SkeletonWrapper>
      </Box>
    </Form>
  );
};

export default ProfileSettings;
