import React from "react";

const DeleteIcon = ({ height = "14", width = "12", fill = "#315fff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.45831 13.375C2.06873 13.375 1.73522 13.2363 1.45779 12.9589C1.18036 12.6814 1.04165 12.3479 1.04165 11.9583V2.75H0.333313V1.33333H3.87498V0.625H8.12498V1.33333H11.6666V2.75H10.9583V11.9583C10.9583 12.3479 10.8196 12.6814 10.5422 12.9589C10.2647 13.2363 9.93123 13.375 9.54165 13.375H2.45831ZM9.54165 2.75H2.45831V11.9583H9.54165V2.75ZM3.87498 10.5417H5.29165V4.16667H3.87498V10.5417ZM6.70831 10.5417H8.12498V4.16667H6.70831V10.5417Z"
        fill={fill}
      />
    </svg>
  );
};

export default DeleteIcon;
