import { Box, Grid } from "@mui/material";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import Text from "components/common/Typography/Text";
import { useFormik } from "formik";
import * as toast from "hooks/notify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordUser,
  clearChangePassword,
} from "redux/features/PrivacySecurity/ChangePasswordSlice";
import { changePasswordButtonBoxStyling } from "styles/Settings/PrivacySecurity";
import { changePasswordSchema } from "validations/Settings/changePassword.schema";
import { SettingsTitle } from "../Wrapper";

const PrivacySecurity = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.auth);
  const {
    isChangePasswordLoading,
    changePasswordMessage,
    changePasswordSuccess,
  } = useSelector((state) => state.changePassword);

  useEffect(() => {
    dispatch(clearChangePassword());
  }, []);

  useEffect(() => {
    if (changePasswordMessage?.length > 1) {
      if (changePasswordSuccess) {
        toast.success(changePasswordMessage);
        formik.resetForm();
      } else if (changePasswordSuccess === false) {
        toast.error(changePasswordMessage);
      }
    }
    dispatch(clearChangePassword());
  }, [changePasswordMessage]);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      values.user_id = userId;
      values.operation = "update_password";
      dispatch(changePasswordUser(values));
    },
  });

  return (
    <>
      <SettingsTitle title="Privacy & Security" />
      <Grid container spacing={1} className="change-password-box">
        <Grid item xs={12} sm={12} md={12}>
          <Box>
            <Box
              sx={{
                borderBottom: "1px solid #D9D9D9",
              }}>
              <Box
                sx={{
                  padding: "20px",
                }}>
                <Text variant="h2" color="blue">
                  Change Password
                </Text>
              </Box>
            </Box>
            <Grid container spacing={2} sx={{ padding: "15px 20px 0px 20px" }}>
              <InputField
                cols={6}
                formik={formik}
                type={"password"}
                name="old_password"
                label="Current password"
                placeholder={"Enter current password"}
              />
              <Grid item xs={6} md={6} sm={6} lg={6} />
              <InputField
                cols={6}
                formik={formik}
                type={"password"}
                name="new_password"
                label="Enter new password"
                placeholder={"new password"}
              />
              <InputField
                cols={6}
                formik={formik}
                type={"password"}
                name="confirm_password"
                label="Re-enter new password"
                placeholder={"new password"}
              />
            </Grid>
            <Box sx={changePasswordButtonBoxStyling}>
              <Button
                isLoading={isChangePasswordLoading}
                id="change-password-button"
                text="Change Password"
                sx={{ marginTop: "5px", height: "38px", padding: "1px 20px" }}
                onClick={formik.handleSubmit}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PrivacySecurity;
