import { Grid } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useRef, useState } from "react";
import { ErrorTooltip } from "../Tooltips/ErrorTooltip";
import moment from "moment";
import Text from "../Typography/Text";
import { Calendar } from "../SVG/Calendar";

export const DatePickerInput = ({
  name,
  inputLabel,
  label,
  value,
  onChange,
  cols = 6,
  validators = [],
  dateFormat = "MM-DD-YYYY",
}) => {
  const inputRef = useRef(null);

  const [fieldError, setFieldError] = useState("");
  const [fieldTouched, setFieldTouched] = useState(false);

  // Inner value is used to store the value of the input field when no onChange is provided
  // If the value is an empty string, we set it to null to tell the date picker it is unset.
  // Note:
  // The date formats should not be needed once the API always returns all dates in ISO-8601 format.
  const [innerValue, setInnerValue] = useState(
    !value ? null : moment(value, [dateFormat, "YYYY-MM-DD"]),
  );

  const handleChange = (value) => {
    const formatted = value.format(dateFormat);
    setFieldTouched(true);

    setInnerValue(value);
    validate(formatted);

    if (onChange) {
      onChange(formatted);
    }
  };

  const validate = (value) => {
    setFieldError(null);
    inputRef.current.setCustomValidity("");
    if (validators.length) {
      for (const validator of validators) {
        const err = validator(value);
        if (err) {
          setFieldError(err);
          inputRef.current.setCustomValidity(err);
        }
      }
    }
  };

  useEffect(() => {
    validate(innerValue?.format(dateFormat));
  }, [validators]);

  return (
    <Grid item xs={cols} sm={cols} md={cols} lg={cols}>
      {label && (
        <Text variant="bodyS" marginY={1}>
          {label}
        </Text>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ErrorTooltip
          arrow
          title={fieldTouched && fieldError ? fieldError : ""}
          placement="right">
          <div>
            <DatePicker
              label={inputLabel}
              format={dateFormat}
              views={["year", "month", "day"]}
              value={innerValue}
              slots={{
                openPickerIcon: Calendar,
              }}
              slotProps={{
                textField: {
                  color: "info",
                  error: fieldTouched && !!fieldError,
                  inputProps: {
                    values: null,
                  },
                  fullWidth: true,
                },
                field: {
                  name,
                  variant: "outlined",
                  size: "small",
                  inputRef,
                  onBlur: () => setFieldTouched(true),
                },
              }}
              onChange={handleChange}
            />
          </div>
        </ErrorTooltip>
      </LocalizationProvider>
    </Grid>
  );
};
