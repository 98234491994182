import { Box } from "@mui/material";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { checkNullOrUndefinedString } from "utils/helper";
import { getSortedFormattedLabs } from "utils/labs/utils";

const LabResultDetailCard = ({ data, marginTop, pastVisit }) => {
  const [sortedFormattedLabs, setSortedFormattedLabs] = useState([]);

  useEffect(() => {
    setSortedFormattedLabs(
      getSortedFormattedLabs(data)?.sortedLabsObservations,
    );
  }, [data]);

  return (
    <Box sx={{ marginTop: marginTop }}>
      {data !== null ? (
        <>
          {!pastVisit ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Box
                  data-testid="labresults-heading"
                  sx={{
                    color: "#2D3748",
                    fontSize: "20px",
                    lineHeight: "28.79px",
                    fontWeight: "700",
                  }}>
                  <span style={{ borderBottom: "2px solid  #1344F1" }}>
                    Lab Results
                  </span>
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}
          <Box
            sx={{
              borderRadius: "10px",
              marginTop: "10px",
            }}>
            <Box
              data-testid="labresults-tests-details"
              sx={{ maxHeight: "490px", marginTop: "10px", overflowY: "auto" }}>
              <Box>
                {sortedFormattedLabs?.map(
                  ({ code, val, at_risk, range }, index) => (
                    <Box
                      sx={{
                        marginLeft: "10px",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "10px",
                      }}>
                      <Box key={`item-${index}`} marginY={1}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Text
                            data-testid={`lab-test-name-${index}`}
                            variant="h3"
                            minWidth="160px">
                            {code ?? ""}
                          </Text>
                          <Text
                            variant="bodyXs"
                            marginLeft="20px"
                            data-testid={`lab-value-${index}`}
                            color={at_risk === "normal" ? "" : "red"}>
                            {checkNullOrUndefinedString(
                              Array.isArray(val) && val.length > 1
                                ? `${val.join("-")}`
                                : val,
                            )}
                          </Text>
                        </Box>
                        {checkNullOrUndefinedString(range) ? (
                          <Text variant="formLabel">
                            Ref:
                            <Text
                              variant="formLabel"
                              data-testid={`lab-range-${index}`}>
                              &nbsp; {range ?? ""}
                            </Text>
                          </Text>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  ),
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box marginY={4}>
          <Text variant="bodyS">No Data Found.</Text>
        </Box>
      )}
    </Box>
  );
};

export default LabResultDetailCard;
