export const Refresh = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <mask
        id="mask0_3200_9216"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3200_9216)">
        <path
          d="M9.825 20.6998C8.10833 20.2165 6.70833 19.2706 5.625 17.8623C4.54167 16.454 4 14.8331 4 12.9998C4 12.0498 4.15833 11.1456 4.475 10.2873C4.79167 9.42897 5.24167 8.64147 5.825 7.92481C6.00833 7.72481 6.23333 7.62064 6.5 7.61231C6.76667 7.60397 7.00833 7.70814 7.225 7.92481C7.40833 8.10814 7.50417 8.33314 7.5125 8.59981C7.52083 8.86647 7.43333 9.11647 7.25 9.34981C6.85 9.86647 6.54167 10.4331 6.325 11.0498C6.10833 11.6665 6 12.3165 6 12.9998C6 14.3498 6.39583 15.554 7.1875 16.6123C7.97917 17.6706 9 18.3915 10.25 18.7748C10.4667 18.8415 10.6458 18.9665 10.7875 19.1498C10.9292 19.3331 11 19.5331 11 19.7498C11 20.0831 10.8833 20.3456 10.65 20.5373C10.4167 20.729 10.1417 20.7831 9.825 20.6998ZM14.175 20.6998C13.8583 20.7831 13.5833 20.7248 13.35 20.5248C13.1167 20.3248 13 20.0581 13 19.7248C13 19.5248 13.0708 19.3331 13.2125 19.1498C13.3542 18.9665 13.5333 18.8415 13.75 18.7748C15 18.3748 16.0208 17.6498 16.8125 16.5998C17.6042 15.5498 18 14.3498 18 12.9998C18 11.3331 17.4167 9.91647 16.25 8.7498C15.0833 7.58314 13.6667 6.9998 12 6.9998H11.925L12.325 7.39981C12.5083 7.58314 12.6 7.81647 12.6 8.09981C12.6 8.38314 12.5083 8.61647 12.325 8.79981C12.1417 8.98314 11.9083 9.07481 11.625 9.07481C11.3417 9.07481 11.1083 8.98314 10.925 8.79981L8.825 6.69981C8.725 6.59981 8.65417 6.49147 8.6125 6.3748C8.57083 6.25814 8.55 6.13314 8.55 5.9998C8.55 5.86647 8.57083 5.74147 8.6125 5.6248C8.65417 5.50814 8.725 5.3998 8.825 5.2998L10.925 3.1998C11.1083 3.01647 11.3417 2.9248 11.625 2.9248C11.9083 2.9248 12.1417 3.01647 12.325 3.1998C12.5083 3.38314 12.6 3.61647 12.6 3.8998C12.6 4.18314 12.5083 4.41647 12.325 4.5998L11.925 4.9998H12C14.2333 4.9998 16.125 5.77481 17.675 7.32481C19.225 8.87481 20 10.7665 20 12.9998C20 14.8165 19.4583 16.4331 18.375 17.8498C17.2917 19.2665 15.8917 20.2165 14.175 20.6998Z"
          fill="#246EFD"
        />
      </g>
    </svg>
  );
};
