import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { flexRowCenterCenter } from "styles";
import Text from "../Typography/Text";

const useStyles = makeStyles(() => ({
  root1: {
    borderRight: "none !important",
    minWidth: "240px",
    "& .MuiTabPanel-root": {
      padding: "0px !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#315FFF !important",
      width: "4px",
      borderTopLeftRadius: "20px",
      borderBottomLeftRadius: "20px",
    },
    "& .MuiButtonBase-root.MuiTab-root": {
      alignItems: "flex-start",
      textTransform: "capitalize",
      marginBottom: "8px",
      minWidth: "240px",
      paddingLeft: "0",
      backgroundColor: "transparent",
    },
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      color: "#1344F1 !important",
      fontWeight: "600",
      backgroundColor: "transparent",
    },
  },
  root2: {
    marginLeft: "30px",
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      data-testid={`selected-tab-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ padding: "0 8px" }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const TabPill = ({
  onTabChange = () => {},
  noContentDataTestId = "tabpill-data-not-found",
  childrenArray = [],
  defaultValue = 0,
  iconPosition = "start",
  icon = null,
  tabSx = {},
  tabTitle,
  componentTitle,
  tabOverFlowDetails,
  titleMargin = "0px",
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);
  const { icon: IconComponent, props: iconProps } = icon || {};

  const handleChange = (_, newValue) => {
    setValue(newValue);
    onTabChange && onTabChange(newValue);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  if (childrenArray.length === 0) {
    return (
      <Box data-testid={noContentDataTestId} padding={2}>
        <Box padding={3} textAlign={"center"}>
          No Data Found
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minWidth: "200px",
        marginTop: "28px",
      }}>
      <Box>
        {tabTitle && (
          <Text
            fontWeight={700}
            color="textSecondary"
            sx={{ marginLeft: "28px" }}
            variant="bodyS">
            {tabTitle}
          </Text>
        )}
        <Tabs
          data-testid={"main-tab-comp"}
          orientation="vertical"
          value={value}
          onChange={handleChange}
          sx={{
            "& .MuiTabs-scroller.MuiTabs-fixed": tabOverFlowDetails?.sx,
          }}
          className={classes.root1}>
          {childrenArray.map(({ title, showIcon }, index) => (
            <Tab
              icon={
                showIcon && (
                  <IconComponent
                    {...iconProps}
                    {...(index === value
                      ? { fill: theme.palette.common.blue }
                      : {})}
                  />
                )
              }
              iconPosition={iconPosition}
              data-testid={`tab-${title}`}
              key={index}
              sx={{
                ...tabSx,
                ...(!showIcon && {
                  "& span.tab-title": { marginLeft: titleMargin },
                }),
              }}
              label={<span className="tab-title">{title}</span>}
              {...a11yProps(value)}
              ref={
                index === childrenArray.length - 1
                  ? tabOverFlowDetails?.ref
                  : null
              }
            />
          ))}
          {tabOverFlowDetails?.isFetching ? (
            <Box
              sx={{
                ...flexRowCenterCenter,
                height: "20px",
                marginBottom: "20px",
                width: "100%",
              }}>
              Loading more...
            </Box>
          ) : (
            ""
          )}
        </Tabs>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}>
        {componentTitle && (
          <Text
            sx={{ marginLeft: "36px", color: theme.palette.common.grey }}
            fontWeight={600}
            variant="bodyS">
            {componentTitle}
          </Text>
        )}
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            borderRadius: "20px",
          }}
          className={classes.root2}>
          {childrenArray.map(({ tab }, index) => (
            <CustomTabPanel
              key={index}
              value={value}
              index={index}
              className={classes.tabPanel}>
              {tab}
            </CustomTabPanel>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TabPill;
