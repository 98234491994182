import { Box, useTheme } from "@mui/material";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import Editor from "components/Editor/Editor";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import InputField from "components/common/FormComponents/InputField";
import CustomModal from "components/common/Modal/CustomModal";
import Button from "components/common/Button/Button";
import { useParams } from "react-router-dom";
import { useFeedback } from "hooks/useFeedback";
import { Refresh } from "components/common/SVG/Refresh";
import { IconButton } from "components/common/Button/IconButton";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchPatient, refreshSmartSummary } from "apiClients/patients";
import { SmartSummaryLoader } from "./common/SmartSummaryLoader";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loader: {
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(-360deg)" },
  },
}));

export const HealthSummary = ({
  smartSummaryfeedback,
  setsmartSummaryFeedback,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { patientId } = useParams();
  const { userId } = useSelector((state) => state.auth);
  const [refetchInterval, setRefetchInterval] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackNote, setFeedbackNote] = useState("");
  const { encounter } = useSelector((state) => state.encounter);
  const { mutationFeedback } = useFeedback(patientId, encounter?.id);

  const { mutateAsync: refresh } = useMutation({
    mutationFn: () => {
      return refreshSmartSummary(patientId);
    },
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ["patientData", patientId],
      });
    };
  }, [queryClient, patientId]);

  const { data: patientData } = useQuery({
    queryKey: ["patientData", patientId],
    queryFn: () => fetchPatient(patientId),
    refetchInterval,
  });

  const handleFeedbackStatus = (feedback) => {
    setFeedbackNote("");
    setShowFeedbackModal(false);
    setsmartSummaryFeedback(feedback);
    const payload = {
      feedback: feedbackNote ?? "",
      item_for_feedback: "smart.summary",
      thumbs_up: feedback,
      user_id: userId,
      item_id: patientId ?? "",
    };
    mutationFeedback.mutateAsync(payload);
  };

  useEffect(() => {
    // we only have feedback text for thumbs down
    // if thumbs up is true, we don't have feedback text
    if (!smartSummaryfeedback?.thumbs_up)
      setFeedbackNote(smartSummaryfeedback?.feedback);
  }, [smartSummaryfeedback]);

  // Set last updated time before refresh is triggered
  // Trigger a refresh of the smart summary
  // Set refetch interval to 5 seconds after refresh is triggered
  const onRefresh = async (background = false) => {
    if (!background) {
      setRefreshing(true);
    }
    setLastUpdated(patientData.smart_summary_last_updated);
    await refresh();
    setRefetchInterval(5000);
  };

  // Once the new patient data has a last updated time greater than the last updated time, we stop the refetch interval.
  if (patientData?.smart_summary_last_updated > lastUpdated) {
    setLastUpdated(null);
    setRefetchInterval(0);
    setRefreshing(false);
  }

  useEffect(() => {
    if (patientData) {
      const lastUpdated = moment(patientData?.smart_summary_last_updated);

      if (lastUpdated.isBefore(moment().subtract(60, "seconds"))) {
        onRefresh(true);
      }
    }
  }, [patientData]);

  if (refreshing) {
    return (
      <Box
        height="80vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center">
        <SmartSummaryLoader
          text="Regenerating Smart Summary"
          lowerText="Estimated Time: 60 Sec."
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: "20px",
        maxHeight: "96vh",
        overflowY: "auto",
      }}>
      <Box data-testid="scd-health-patient-summary">
        <SkeletonWrapper
          multipleCount={10}
          id={"skeleton-smart-summary"}
          condition={patientData?.smart_summary_text}
          props={[SkeletonPropsStyleGenerator("100%", "100px")]}>
          <Box padding="12px" display="flex" justifyContent={"flex-end"}>
            <Box
              sx={{
                "svg g path": {
                  fill:
                    refetchInterval > 0
                      ? theme.palette.common.grey
                      : theme.palette.common.blue,
                },
              }}
              title={
                refetchInterval > 0
                  ? "Refreshing Smart Summary"
                  : "Refresh Smart Summary"
              }
              className={refetchInterval > 0 ? classes.loader : ""}>
              <IconButton
                disabled={refetchInterval > 0}
                variant="text"
                onClick={() => {
                  onRefresh();
                }}>
                <Refresh />
              </IconButton>
            </Box>
          </Box>
          <Editor data={patientData?.smart_summary_text} editable={false} />
        </SkeletonWrapper>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginRight: "15px",
          position: "absolute",
          top: -40,
          right: 12,
        }}>
        <ThumbUpIcon
          onClick={() => handleFeedbackStatus(true)}
          sx={{
            color:
              smartSummaryfeedback?.thumbs_up === "" ||
              smartSummaryfeedback?.thumbs_up === undefined
                ? "#ccc"
                : smartSummaryfeedback?.thumbs_up
                  ? "#246EFD"
                  : "#ccc",
            cursor: "pointer",
          }}
        />
        <ThumbDownIcon
          onClick={() => setShowFeedbackModal(true)}
          sx={{
            color:
              smartSummaryfeedback?.thumbs_up === "" ||
              smartSummaryfeedback?.thumbs_up === undefined
                ? "#ccc"
                : smartSummaryfeedback?.thumbs_up
                  ? "#ccc"
                  : "#246EFD",
            marginLeft: "14px",
            cursor: "pointer",
          }}
        />
      </Box>
      <CustomModal
        fullWidth
        maxWidth="md"
        title="Smart Summary Feedback"
        open={showFeedbackModal}
        setOpen={() => {
          handleFeedbackStatus(false);
        }}
        actions={[
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "4px",
              gap: 2,
            }}>
            <Button
              text={"Cancel"}
              onClick={() => handleFeedbackStatus(false)}
              variant="outlined"
              sx={{ marginRight: "10px", width: "150px" }}
            />
            <Button
              onClick={() => handleFeedbackStatus(false)}
              text={"Save"}
              sx={{ width: "150px" }}
            />
          </Box>,
        ]}>
        <Box paddingY={0.5} paddingX={1.1}>
          <Text variant="h3" marginY={1}>
            Feedback
          </Text>
          <InputField
            rows={4}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            flex={1}
            multiline
            name={"scd-assesment-notes"}
            labelVariant="formLabel"
            value={feedbackNote}
            onChange={(e) => setFeedbackNote(e.target.value)}
          />
        </Box>
      </CustomModal>
    </Box>
  );
};
