import moment from "moment";

// Function to check for null or undefined strings and format them
export const checkNullOrUndefinedString = (str) => {
  if (str == null || str === undefined) {
    return "";
  } else {
    // Ensure str is a string before performing replacements
    str = String(str)
      .replace(/null/gi, "")
      .replace(/undefined/gi, "");

    if (str.length > 1) return str.charAt(0).toUpperCase() + str.slice(1);
    else return null;
  }
};

// Function to calculate age from date of birth using moment.js
export const calculateAge = (dob) => {
  if (!dob) return "";
  return moment().diff(moment(dob), "years");
};

export const isObjectEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
export const isArrayEmpty = (arr) => {
  return Array.isArray(arr) && arr.length === 0;
};
