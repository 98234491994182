import { Box, Grid, useTheme } from "@mui/material";
import CustomModal from "components/common/Modal/CustomModal";
import Text from "components/common/Typography/Text";
import { useState } from "react";
import { useSelector } from "react-redux";
import LabResultDetail from "./LabResultDetail";
import CustomTooltip from "components/common/Tooltips/CustomTooltip";
import { checkNullOrUndefinedString } from "utils/helper";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import moment from "moment";
import { dateFormat } from "utils/date";
import { ArrowUp } from "components/common/SVG/ArrowUp";
import { ArrowDown } from "components/common/SVG/ArrowDown";

const LabResults = ({ sortedFormattedLabs, LabObservedDateTime }) => {
  const theme = useTheme();
  const [openVitals, setOpenVitals] = useState(false);
  const { labsResultsSuccess } = useSelector((state) => state.resultsLabs);
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient,
  );

  return (
    <Box
      sx={{
        marginTop: "35px",
        height: "40vh",
      }}>
      <Box sx={{ height: "100%" }}>
        <Box
          data-testid="scd-labs-modal-btn"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
          onClick={() => {
            setOpenVitals(true);
          }}>
          <SkeletonWrapper
            condition={labsResultsSuccess}
            multipleCount={1}
            id="measured-date-skeleton"
            props={[SkeletonPropsStyleGenerator("20%", "40px")]}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}>
              <Text
                data-testid="scd-lab-results-date-measured-heading"
                variant="h2"
                marginLeft="20px">
                Measured:{" "}
              </Text>
              <Text
                data-testid="scd-lab-results-date-measured-date"
                variant="h3"
                marginLeft="8px">
                {LabObservedDateTime
                  ? moment(LabObservedDateTime).format(dateFormat)
                  : ""}
              </Text>
            </Box>
          </SkeletonWrapper>
          <Text variant="h2" color={"lightBlue"} sx={{ marginRight: "20px" }}>
            {" "}
            Previous Labs{" "}
          </Text>
        </Box>
        <SkeletonWrapper
          condition={labsResultsSuccess}
          multipleCount={5}
          id="lab-res-details-skeleton"
          props={[SkeletonPropsStyleGenerator("95%", "40px", "150px")]}>
          {sortedFormattedLabs?.length > 0 ? (
            <>
              <Grid
                container
                spacing={2} // Add spacing between items
                sx={{
                  maxHeight: "36vh",
                  overflowY: "auto",
                  padding: "20px", // Add padding around the grid
                }}>
                {sortedFormattedLabs?.map(
                  ({ code, val, at_risk, range, isUp }, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={6}
                      xl={6}
                      xxl={4}
                      key={`item-${index}`}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                        }}>
                        <Text
                          data-testid={`scd-labs-label-${code}`}
                          variant="h3"
                          minWidth="70px"
                          sx={{
                            wordBreak: "break-word",
                            whiteSpace: "normal",
                            flex: 1,
                            fontWeight: "700",
                          }}>
                          {code}:
                        </Text>
                        {checkNullOrUndefinedString(range) ? (
                          <CustomTooltip
                            tooltipText={
                              <Box sx={{ padding: "5px" }}>
                                <Box>
                                  <Text variant="h3" sx={{ fontWeight: 500 }}>
                                    <strong>{code} Reference Range</strong>
                                  </Text>
                                </Box>
                                <Box sx={{ marginTop: "5px" }}>
                                  <Text variant="formLabel">
                                    {checkNullOrUndefinedString(range)}
                                  </Text>
                                </Box>
                              </Box>
                            }
                            arrow>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}>
                              <Text
                                data-testid={`scd-labs-value-${val}`}
                                variant="bodyS"
                                marginLeft="10px"
                                marginRight="10px"
                                color={at_risk === "at_risk" ? "redLight" : ""}
                                sx={{ cursor: "context-menu" }}>
                                {checkNullOrUndefinedString(
                                  Array.isArray(val) && val.length > 1
                                    ? val.join("-")
                                    : val,
                                )}
                              </Text>
                              {at_risk === "at_risk" ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    padding: "5px",
                                    borderRadius: "10px",
                                    justifyContent: "center",
                                    background: theme.palette.error.light,
                                  }}>
                                  {isUp ? <ArrowUp /> : <ArrowDown />}
                                </Box>
                              ) : null}
                            </Box>
                          </CustomTooltip>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            <Text
                              data-testid={`scd-labs-value-${val}`}
                              variant="bodyS"
                              marginLeft="10px"
                              marginRight="10px"
                              color={at_risk === "at_risk" ? "redLight" : ""}>
                              {checkNullOrUndefinedString(
                                Array.isArray(val) && val.length > 1
                                  ? val.join("-")
                                  : val,
                              )}
                            </Text>
                            {at_risk === "at_risk" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  borderRadius: "10px",
                                  justifyContent: "center",
                                  background: theme.palette.error.light,
                                }}>
                                {isUp ? <ArrowUp /> : <ArrowDown />}
                              </Box>
                            ) : null}
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  ),
                )}
              </Grid>
            </>
          ) : (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "250px",
                flex: 1,
              }}>
              No Data Found
            </Box>
          )}
        </SkeletonWrapper>
        <CustomModal
          open={openVitals}
          setOpen={setOpenVitals}
          title={"Previous Lab Results"}
          fullWidth
          maxWidth="lg">
          <LabResultDetail
            patientName={
              (getSpecificPatientData?.first_name
                ? getSpecificPatientData?.first_name
                : "") +
              " " +
              (getSpecificPatientData?.last_name
                ? getSpecificPatientData?.last_name
                : "")
            }
          />
        </CustomModal>
      </Box>
    </Box>
  );
};

export default LabResults;
