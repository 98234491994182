import { Box } from "@mui/material";
import CustomModal from "./CustomModal";
import Text from "../Typography/Text";
import Button from "../Button/Button";
import { memo } from "react";

export const DeleteConfirmationModal = memo(
  ({
    open,
    onClose,
    onConfirm,
    title = "Confirm deletion",
    message = "Are you sure you want to delete this?",
    children,
  }) => {
    return (
      <CustomModal
        open={open}
        setOpen={onClose}
        title={title}
        aria-labelledby="delete-confirmation-modal-title"
        aria-describedby="delete-confirmation-modal-description">
        <Box sx={{ padding: "24px 0" }}>
          <Text variant="bodyS">{children ? children : message}</Text>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}>
          <Button
            variant="outlined"
            text="Cancel"
            onClick={onClose}
            sx={{ marginRight: 2 }}
          />
          <Button text="Delete" onClick={onConfirm} color="error" />
        </Box>
      </CustomModal>
    );
  },
);
