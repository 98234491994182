import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

export const useNavigationBlocker = ({ when, message }) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator;

  // Route Change Confirmation
  useEffect(() => {
    if (!when) return;

    const originalPush = navigator.push;
    const originalReplace = navigator.replace;

    const confirmNavigation = (method, ...args) => {
      if (window.confirm(message)) {
        method.apply(navigator, args);
      }
    };

    navigator.push = (...args) => confirmNavigation(originalPush, ...args);
    navigator.replace = (...args) =>
      confirmNavigation(originalReplace, ...args);

    return () => {
      navigator.push = originalPush;
      navigator.replace = originalReplace;
    };
  }, [when, navigator, message]);

  // Tab Close / Refresh Confirmation
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (when) {
        event.preventDefault();
        event.returnValue = ""; // Required for modern browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [when, message]);
};
