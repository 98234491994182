import { Box, Grid, useTheme } from "@mui/material";
import CustomModal from "components/common/Modal/CustomModal";
import * as toast from "hooks/notify";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";
import { HealthSummary } from "./components/HealthSummary";
import Medications from "./components/Medications/Medications";
import { scdBoxStyling } from "styles/SCD/healthSummaryStyle";
import {
  clearAllHistoryData,
  getAllHistoryList,
} from "redux/features/AllHistories/AllHistorySlice";
import {
  clearAllergiesData,
  getAllergiesByPatientId,
} from "redux/features/Allergies/AllergiesSlice";
import SmartSummaryIcon from "../../../assets/svg/light/ai_sparkle.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassFullRoundedIcon from "@mui/icons-material/HourglassFullRounded";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import {
  clearEncounterData,
  createOrUpdateEncounter,
  getEncounterByAppointment,
  getEncounterById,
} from "redux/features/Encounter/EncounterSlice";
import { getFamilyHistoryList } from "redux/features/FamilyHistory/FamilyHistorySlice";
import { clearMedicationsData } from "redux/features/Medications/MedicationsSlice";
import { clearMedicinesData } from "redux/features/Medications/MedicinesSlice";
import { clearAllOrderMedicationsStates } from "redux/features/Medications/OrderMedicationsSlice";
import {
  clearLabsResultsMessage,
  getLabsResults,
} from "redux/features/Patients/ViewLabResultsSlice";
import {
  clearSpecificPatientData,
  getSpecificPatientDetails,
} from "redux/features/Patients/getSpecificPatientsSlice";
import { clearVitalsData } from "redux/features/Vitals/VitalsSlice";
import { DEBOUNCE_DELAY, debounce } from "utils/debouncer";
import EndVisit from "./components/EndVisit";
import Plan from "./components/AssesmentAndPlan/Plan";
import VitalLabMedWrapper from "./components/VitalLabMedWrapper";
import SCDHeader from "./components/SCDHeader";
import Button from "components/common/Button/Button";
import rightArrow from "assets/svg/light/rightArrow.svg";
import { PastVisit } from "./components/PastVisit/PastVisit";
import {
  planBoxDescriptionStyling,
  planBoxStylingWrapper,
  planBoxTitleStyling,
} from "styles/SCD/planStyles";
import Text from "components/common/Typography/Text";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import InputField from "components/common/FormComponents/InputField";
import { clearLabsList, getLabsList } from "redux/features/Labs/labsSlice";
import { clearPharmacyData } from "redux/features/Pharmacy/PharmacySlice";
import { removeSnakeCasingAndCapitalize } from "utils/convertJsonToMarkDown";
import { useFeedback } from "hooks/useFeedback";
import { useQueryClient } from "@tanstack/react-query";
import { PatientDetailWrapper } from "components/common/PatientDetailWrapper";
import { IntakeFormProvider } from "./PatientIntakeForm/common/IntakeFormProvider";
import { PatientIntakeForm } from "./PatientIntakeForm/PatientIntakeForm";
import { createCustomTheme } from "styles/theming/theme";
import { setCurrentNavigationPath } from "redux/features/globalStateSlice";
import { PatientDocuments } from "./components/documents";
import EndVisitStepper from "./components/EndVisitStepper";

const PatientDetail = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const viewWidth = window.innerWidth;
  //get query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encounter_id = queryParams.get("encounter_id");
  const reviewLater = queryParams.get("review_later");
  const queryClient = useQueryClient();
  let { patientId, appointmentId } = useParams();
  const scdRef = useRef();
  const markdownContentRef = useRef();
  const labAbortControllerRef = useRef(null);

  const [isPostCalled, setIsPostCalled] = useState(null);
  const [scdContent, setScdContent] = useState({});
  const [componentName, setComponentName] = useState(
    queryParams.get("review_later") ? "endVisit" : null,
  );

  const [startAppointment, setStartAppointment] = useState(false);
  const [isClosingEncounter, setIsClosingEncounter] = useState(false);
  const [feedbackNote, setFeedbackNote] = useState("");
  const [otherEncounter, setOtherEncounter] = useState({});
  const [smartSummaryfeedback, setsmartSummaryFeedback] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [endNoteFeedback, setEndNoteFeedback] = useState("");
  const [pastVisit, setPastVisit] = useState(false);
  const [planMedicineData, setPlanMedicineData] = useState([]);
  const [endVisitActive, setEndVisitActive] = useState(0);

  // encounter request response
  const { encounter, encounterSuccess, encounterMessage, isEncounterLoading } =
    useSelector((state) => state.encounter);

  const { processingStatus, recordingIsLoading } = useSelector(
    (state) => state.recording,
  );
  const { account_id, userId } = useSelector((state) => state.auth);

  const { activeLocation: { value: activeLocationId } = {} } = useSelector(
    (state) => state.locations,
  );

  const { feedbackList, mutationFeedback } = useFeedback(
    patientId,
    otherEncounter?.id || encounter?.id,
  );

  useEffect(() => {
    if (feedbackList?.length) {
      const findSmartSummaryFeedback = findLatestFeedback(
        feedbackList,
        "smart.summary",
      );
      const findEndNoteFeedback = findLatestFeedback(
        feedbackList,
        "smart.endnote",
      );
      setEndNoteFeedback(findEndNoteFeedback);
      setsmartSummaryFeedback(findSmartSummaryFeedback);
    }
  }, [feedbackList, patientId, otherEncounter, encounter]);

  const findLatestFeedback = (feedbackArray, itemType) => {
    // Smart summary is associated with a patient and endnote is associated with an encounter
    const itemId =
      itemType === "smart.summary"
        ? patientId
        : otherEncounter?.id
          ? otherEncounter?.id
          : encounter?.id;
    return feedbackArray
      .filter(
        (item) =>
          item.item_for_feedback === itemType &&
          Number(item.item_id) === Number(itemId),
      )
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];
  };

  // Toast pop up on encounter response
  useEffect(() => {
    if (encounterSuccess === false) {
      toast.error(encounterMessage);
    }
  }, [encounterMessage]);

  useEffect(() => {
    // Api hit for labs data
    dispatch(getLabsResults(patientId));

    // Api hit for familyhistory for specific patient
    dispatch(getFamilyHistoryList(patientId));

    // Api hit for specific patient
    dispatch(getSpecificPatientDetails(patientId));

    // Api hit for allergies by patient id
    dispatch(getAllergiesByPatientId(patientId));

    // Api hit for all histories by patient id
    dispatch(getAllHistoryList(patientId));

    appointmentId && dispatch(getEncounterByAppointment(appointmentId));
  }, [patientId]);

  useEffect(() => {
    encounter?.sign_close_ind === "c" && setStartAppointment(false);
  }, [encounter]);

  useEffect(() => {
    return () => {
      // Clear vitals data
      dispatch(clearVitalsData());

      // clear labs data
      dispatch(clearLabsResultsMessage());

      // Clear patient data from global state
      dispatch(clearSpecificPatientData());

      // Clear all history data from global state
      dispatch(clearAllHistoryData());

      // Clear all medicines data
      dispatch(clearMedicinesData());

      // Clear encounter data from global state
      dispatch(clearEncounterData());

      // Clear encounter data by appointment
      dispatch(clearAllergiesData());

      // Clear all medications data
      dispatch(clearMedicationsData());

      //clear pharmacy data
      dispatch(clearPharmacyData());

      //clear orderMedication/prescriptions data
      dispatch(clearAllOrderMedicationsStates());

      queryClient.removeQueries(["encountersByPatient", patientId]);

      // clear labs list and stop current api request
      _clearLabsData();
    };
  }, []);

  const getLabs = debounce((value) => {
    if (value?.length > 0) {
      // Cancel previous request if it exists
      abortLabsCurrentRequest();

      // Create a new AbortController
      const abortController = new AbortController();
      labAbortControllerRef.current = abortController;

      dispatch(getLabsList(value, labAbortControllerRef.current.signal));
    }
  }, DEBOUNCE_DELAY);

  // Cancel previous request if it exists
  function abortLabsCurrentRequest() {
    if (labAbortControllerRef.current) {
      labAbortControllerRef.current.abort();
    }
  }

  function _clearLabsData() {
    abortLabsCurrentRequest();
    dispatch(clearLabsList());
  }

  // To send the encounter after validations
  function sendEncounter(plan_note, signCloseIn) {
    let encounterData = {
      plan_note: plan_note,
      patient_id: patientId,
      location_id: activeLocationId,
      practitioners: [],
      user_id: userId,
      visit_type: "",
      outcome: "",
      assessment_notes: ".",
      appointment_id: appointmentId ?? null,
      icds: [], // we are not sure yet whether we need icds for the mvp or not...
      cpts: [],
      datetime: new Date().toISOString(),
      followup_date: null,
      account_id: account_id,
      followup_selection: "",
      sign_close_ind: signCloseIn,
    };

    // adding id if encounter exists
    if (encounter?.id) {
      encounterData.id = encounter?.id;
    }

    // Clear encounter data from global state
    dispatch(clearEncounterData());

    // create or update encounter on the basis of data and validations
    dispatch(
      createOrUpdateEncounter(
        encounterData,
        encounter?.id ? "PUT" : "POST",
        encounter?.id,
      ),
    );
  }

  const close = () => {
    dispatch(setCurrentNavigationPath(null));
    setComponentName(null);
    setOtherEncounter({});
  };

  function onStartAppointment() {
    //encounter_id is the query param that we are getting from the url
    setStartAppointment(true);
    if (!appointmentId && !encounter_id) {
      dispatch(
        createOrUpdateEncounter(
          {
            patient_id: patientId,
            location_id: activeLocationId,
            user_id: userId,
            datetime: new Date().toISOString(),
            account_id: account_id,
            sign_close_ind: "o",
          },
          "POST",
          null,
        ),
      );
    }
  }

  const healthMedTabsArray = [
    {
      title: "Smart Summary",
      tab: (
        <HealthSummary
          smartSummaryfeedback={smartSummaryfeedback}
          setsmartSummaryFeedback={setsmartSummaryFeedback}
        />
      ),
      tabIcon: true,
      tabIconSrc: SmartSummaryIcon,
      left: true,
    },
    {
      title: "Medications",
      tab: <Medications />,
    },
    {
      title: "Documents",
      tab: <PatientDocuments />,
    },
  ];

  const handleFeedbackStatus = (feedback) => {
    setFeedbackNote("");
    setShowFeedbackModal(false);
    const payload = {
      feedback: feedbackNote ?? "",
      item_for_feedback: "smart.endnote",
      thumbs_up: feedback,
      user_id: userId,
      item_id: (otherEncounter?.id || encounter?.id) ?? "",
    };
    setEndNoteFeedback(payload);
    mutationFeedback.mutateAsync(payload);
  };

  useEffect(() => {
    encounter_id && dispatch(getEncounterById(encounter_id));
  }, [encounter_id]);

  // open the modal if the encounter is in review later state
  // and the user is not in the middle of recording
  // and we have the reviewLater query param
  useEffect(() => {
    reviewLater &&
      encounter?.smart_note_data !== null &&
      setComponentName("endVisit");
  }, [reviewLater]);

  useEffect(() => {
    if (encounterSuccess && isClosingEncounter) {
      queryClient.invalidateQueries({
        queryKey: ["encountersByPatient", patientId],
      });
      setComponentName(null);
      setStartAppointment(false);
      setPastVisit(false);
      setOtherEncounter({});
      dispatch(clearEncounterData());
      setIsClosingEncounter(false);
      dispatch(getEncounterById(encounter_id || encounter?.id));
    }
  }, [isClosingEncounter, encounterSuccess]);

  useEffect(() => {
    if (componentName) {
      // Using switch-case to determine scdContent based on componentName
      switch (componentName) {
        case "pastVisits":
          setScdContent({
            body: (
              <PastVisit
                setOtherEncounter={setOtherEncounter}
                setComponentName={setComponentName}
                onClose={close}
              />
            ),
          });
          break;
        case "patientIntake":
          setScdContent({
            body: (
              <IntakeFormProvider patientId={patientId}>
                <PatientIntakeForm onClose={close} />
              </IntakeFormProvider>
            ),
          });
          break;
        default:
          setScdContent(null);
          break;
      }
    }
  }, [componentName]);

  useEffect(() => {
    if (location?.state?.intakeFlag === true) {
      setComponentName("patientIntake");
    }
  }, [location.state]);

  return (
    <Box>
      <CustomModal
        fullWidth
        maxWidth="md"
        title="Smart Notes Feedback"
        open={showFeedbackModal}
        setOpen={() => {
          handleFeedbackStatus(false);
        }}
        actions={[
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              text={"Cancel"}
              onClick={() => handleFeedbackStatus(false)}
              variant="outlined"
              sx={{ marginRight: "10px", width: "150px" }}
            />
            <Button
              onClick={() => handleFeedbackStatus(false)}
              text={"Save"}
              sx={{ width: "150px" }}
            />
          </Box>,
        ]}>
        <Box paddingY={2} paddingX={2}>
          <Text variant="h3" marginY={1}>
            Feedback
          </Text>
          <InputField
            rows={4}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            flex={1}
            multiline
            name={"scd-assesment-notes"}
            labelVariant="formLabel"
            value={feedbackNote}
            onChange={(e) => setFeedbackNote(e.target.value)}
          />
        </Box>
      </CustomModal>
      <Grid container spacing={2}>
        <Grid item lg={12} xl={12} xxl={12} md={12} sm={12} xs={12}>
          <SCDHeader
            ref={scdRef}
            setIsPostCalled={setIsPostCalled}
            patientId={patientId}
            setStartAppointment={onStartAppointment}
            startAppointment={startAppointment}
            setScdContent={setScdContent}
            setComponentName={setComponentName}
          />
          {componentName !== null ? (
            componentName !== "endVisit" ? (
              <PatientDetailWrapper>{scdContent?.body}</PatientDetailWrapper>
            ) : (
              <EndVisitStepper
                ref={markdownContentRef}
                isPostCalled={isPostCalled}
                sendEncounter={sendEncounter}
                setComponentName={setComponentName}
                encounter={otherEncounter?.id ? otherEncounter : encounter}
              />
            )
          ) : (
            <Box sx={{ marginTop: "23px" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  className="healthsummary_wrapper"
                  sx={{ flex: 1 }}>
                  <Box
                    sx={{ ...scdBoxStyling(), height: "100%", zIndex: 1 }}
                    data-testid="scd-health-summary-box-id">
                    <HorizontalTabs
                      childrenArray={healthMedTabsArray}
                      borderTab={true}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  data-testid="scd-vital-labs-box-id"
                  className="vital-lab-med-wrapper"
                  sx={{ height: "100%" }}>
                  <Box
                    sx={{
                      ...scdBoxStyling(),
                      height: "50vh",
                    }}>
                    <VitalLabMedWrapper />
                  </Box>
                  <Box sx={{ position: "relative", marginTop: "5px" }}>
                    <Box
                      sx={{
                        marginTop: "15px",
                        height: "50vh",
                      }}
                      data-testid="scd-plan-box-id">
                      <Box
                        sx={{
                          ...planBoxStylingWrapper,
                          height: "100%",
                        }}>
                        <Box
                          sx={planBoxTitleStyling(viewWidth)}
                          data-testid={"assesment-and-plan-heading-id"}>
                          <Box>
                            <img
                              src={SmartSummaryIcon}
                              alt="Tab Icon"
                              style={{ marginRight: "5px" }}
                            />
                          </Box>
                          <Text variant="h3">Smart Recommendations</Text>
                        </Box>
                        <Box sx={planBoxDescriptionStyling(viewWidth, theme)}>
                          Coming Soon
                        </Box>
                      </Box>
                    </Box>
                    {encounter?.sign_close_ind === "c" && (
                      <div
                        style={{
                          position: "absolute",
                          top: "20px",
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgb(253 250 250 / 50%)",
                          zIndex: 1000,
                          pointerEvents: "auto",
                          cursor: "not-allowed",
                        }}></div>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientDetail;
