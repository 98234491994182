import Cookies from "js-cookie";
import axios from "axios";

const HttpMethod = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
});

const DEV_URL = "https://dev.lab.nephrolytics.ai";

export async function getAxiosBaseOptions(method, url) {
  let baseURL = window.location.origin;
  let formattedUrl = url;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    baseURL = "";

    // Remove the DEV_URL from the URL to allow the proxy server to forward requests to the API server.
    formattedUrl = removeDevUrlFromUrl(url);
  }

  if (!Cookies.get("csrftoken") && method !== HttpMethod.GET) {
    await axios.request({
      baseURL,
      method: HttpMethod.GET,
      url: "/api/csrf-token/",
    });
  }

  return {
    baseURL,
    url: formattedUrl,
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
  };
}

// EX. {filter: {field: "name", operator: "contains", value: "John"}, sort: {field: "name", order: "asc"}}
export function parseQueryOptions(queryOptions) {
  const params = new URLSearchParams();
  if (queryOptions.filter) {
    params.append("filter", JSON.stringify(queryOptions.filter));
  }
  if (queryOptions.sort) {
    params.append("sort", JSON.stringify(queryOptions.sort));
  }
  if (queryOptions.page) {
    params.append("page", queryOptions.page);
  }
  return params.toString();
}

export async function apiGet(url, signal) {
  const method = HttpMethod.GET;
  const options = await getAxiosBaseOptions(method, url);
  return axios.request({
    ...options,
    method,
    signal,
  });
}

export async function apiPost(url, data) {
  const method = HttpMethod.POST;
  const options = await getAxiosBaseOptions(method, url);
  return axios.request({
    ...options,
    method,
    data: data,
  });
}

export async function apiPut(url, data) {
  const method = HttpMethod.PUT;
  const options = await getAxiosBaseOptions(method, url);
  return axios.request({
    ...options,
    method,
    data: data,
  });
}

export async function apiDelete(url) {
  const method = HttpMethod.DELETE;
  const options = await getAxiosBaseOptions(method, url);
  return axios.request({
    ...options,
    method,
  });
}

// During development, the API URL is prefixed with the DEV_URL constant.
//  To allow the proxy server to forward requests to the API server, the DEV_URL is removed from the URL.
function removeDevUrlFromUrl(url) {
  return url.replace(DEV_URL, "");
}
