const startRecording = async (
  setRecordedUrl,
  setIsRecording,
  mediaStream,
  mediaRecorder,
  chunks,
) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const supportedType = MediaRecorder.isTypeSupported("audio/webm")
      ? "audio/webm"
      : "audio/mp4";
    mediaStream.current = stream;
    mediaRecorder.current = new MediaRecorder(stream, {
      mimeType: supportedType,
    });
    mediaRecorder.current.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunks.current.push(e.data);
      }
    };
    mediaRecorder.current.onstop = () => {
      const recordedBlob = new Blob(chunks.current, { type: supportedType });
      const url = URL.createObjectURL(recordedBlob);
      setRecordedUrl(url);
      setIsRecording(false); // Set recording status to false when recording stops
      chunks.current = [];
    };
    mediaRecorder.current.start();
    setIsRecording(true); // Set recording status to true when recording starts
    return true;
  } catch (error) {
    console.error("Error accessing microphone:", error); // eslint-disable-line no-console
    return false;
  }
};

const downloadRecording = (recordedUrl) => {
  const a = document.createElement("a");
  a.href = recordedUrl;
  a.download = "Audio recording";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const stopRecording = (
  mediaStream,
  mediaRecorder,
  setIsRecordingPaused,
  setIsRecording,
) => {
  if (
    mediaRecorder.current &&
    (mediaRecorder.current.state === "recording" ||
      mediaRecorder.current.state === "paused")
  ) {
    mediaRecorder.current.stop();
    setIsRecordingPaused(false);
    setIsRecording(false);
  }
  if (mediaStream.current) {
    mediaStream.current.getTracks().forEach((track) => {
      track.stop();
    });
  }
};

const pauseRecording = (
  mediaStream,
  mediaRecorder,
  isRecordingPaused,
  setIsRecordingPaused,
) => {
  if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
    mediaRecorder.current.pause();
    setIsRecordingPaused(true);
  }
};

const resumeRecording = async (
  mediaStream,
  mediaRecorder,
  setIsRecording,
  setIsRecordingPaused,
) => {
  if (mediaRecorder.current) {
    mediaRecorder.current.resume();
    setIsRecording(true);
    setIsRecordingPaused(false);
  }
};

async function convertBlob(blobUrl) {
  // Fetch the Blob data
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  return blob; // Return the original blob without modifying its type
}

export {
  startRecording,
  downloadRecording,
  stopRecording,
  pauseRecording,
  resumeRecording,
  convertBlob,
};
