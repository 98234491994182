import { useState, useEffect } from "react";
import moment from "moment";

const useCurrentTime = (format = "H : mm") => {
  const [time, setTime] = useState(moment().format(format));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format(format));
    }, 60000);

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [format]);

  return time;
};

export default useCurrentTime;
