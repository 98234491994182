import { createTheme, responsiveFontSizes } from "@mui/material";

export function createCustomTheme(options = {}) {
  const {
    palette = {
      background: {
        default: "#F8F7FE",
        paper: "#fff",
      },
      common: {
        black: "#222222",
        lightBlack: "#555555",
        white: "#fff",
        lightGrey: "#F5F5F5",
        grey: "#A1A0A0",
        blue: "#246EFD",
        lightBlue: "#E4EDFF",
        green: "#5ebc4e",
        red: "#fc5a5a",
        dark: "#272a40",
        softRed: "#ff5e5e",
        purple: "#711aff",
        softBlue: "#E5EBFF",
        borderColor: "#e0e0e0",
      },
      mode: "light",
      themePrimary: {
        primary: "#2D3748",
        secondary: "#555555",
        tertiary: "#315fff",
        contrastText: "#fff",
      },
      primary: {
        main: "#315fff",
        light: "#6573ff",
        dark: "#0039cb",
        contrastText: "#fff",
      },
      // TODO: update these colors once we have secondary colors
      secondary: {
        main: "#f50057",
        light: "#f73378",
        dark: "#ab003c",
        contrastText: "#fff",
      },
      tertiary: {
        main: "#333",
        light: "#555",
        dark: "#111",
        contrastText: "#fff",
      },
      error: {
        main: "#d32f2f",
        light: "#ef5350",
        dark: "#c62828",
        contrastText: "#fff",
      },
      warning: {
        main: "#ff9800",
        light: "rgb(255, 200, 117)",
        dark: "rgb(178, 122, 0)",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      info: {
        main: "#2196f3",
        light: "rgb(71, 145, 219)",
        dark: "rgb(17, 82, 147)",
        contrastText: "#fff",
      },
      success: {
        main: "#4caf50",
        light: "rgb(111, 207, 151)",
        dark: "rgb(53, 122, 63)",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      text: {
        primary: "#2D3748",
        secondary: "#878787",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.23)",
      },
      action: {
        active: "rgba(0, 0, 0, 0.54)",
        hover: "rgba(0, 0, 0, 0.08)",
        hoverOpacity: 0.08,
        selected: "rgba(0, 0, 0, 0.14)",
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
    },
    typography = {
      fontFamily: "Montserrat, sans-serif",
      h1: {
        fontWeight: 700,
        fontSize: "1.25rem",
        lineHeight: 1.3,
        color: palette.text.primary,
      },
      h2: {
        fontWeight: 600,
        fontSize: "1.125rem",
        lineHeight: 1.2,
        color: palette.text.primary,
      },
      h3: {
        fontWeight: 600,
        fontSize: "0.875rem",
        lineHeight: 1.125,
        color: palette.text.primary,
      },
      h4: {
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: 1.125,
        color: palette.text.primary,
      },
      modalLabel: {
        fontWeight: 600,
        fontSize: "0.75rem",
        lineHeight: 0.875,
        color: palette.text.primary,
      },
      formLabel: {
        fontWeight: 400,
        fontSize: "0.688rem",
        lineHeight: 0.875,
        color: palette.text.primary,
      },
      propertyLabel: {
        fontWeight: 600,
        fontSize: "0.688rem",
        lineHeight: 0.875,
        color: palette.text.primary,
      },
      bodyL: {
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: 1.25,
        color: palette.text.primary,
      },
      bodyM: {
        fontWeight: 400,
        fontSize: "0.938rem",
        lineHeight: 1.125,
        color: palette.text.primary,
      },
      bodyS: {
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "1rem",
        color: palette.text.primary,
      },
      bodyXs: {
        fontWeight: 400,
        fontSize: "0.813rem",
        lineHeight: "18px",
        color: palette.text.primary,
      },
      body: {
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: "21px",
        color: palette.text.secondary,
      },
      bodyBold: {
        fontWeight: 700,
        fontSize: "0.875rem",
        lineHeight: "21px",
        color: palette.text.primary,
      },

      button: {
        fontWeight: 600,
        fontSize: "0.938rem",
        lineHeight: 1,
      },
      caption: {
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: 1,
      },
      overline: {
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: 1,
      },
    },
    shape = {
      borderRadius: 8,
    },

    breakpoints = {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1024,
        xl: 1200,
        xxl: 1536,
      },
    },

    ...other
  } = options;

  let theme = createTheme({
    palette,
    typography,
    shape,
    breakpoints,
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            height: "40px",
            minWidth: "100px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
          loadingIndicator: {
            color: "white",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "&.MuiTextField-root": {
              border: "none",
            },
            "& .MuiFormLabel-colorError": {
              color: palette.error.main,
            },
            "& .MuiOutlinedInput-root": {
              border: palette.text.hint,
              borderRadius: "8px",
              fontWeight: "400",
              "&.Mui-error": {
                borderColor: palette.error.main,
                fieldset: {
                  borderColor: palette.error.main,
                  color: palette.error.main,
                },

                "&:hover fieldset": {
                  borderColor: palette.error.main,
                },
              },
              "&:hover fieldset": {
                borderColor: "inherit",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: "0px",
            justifyContent: "flex-start",
          },
          labelPlacementTop: {
            alignItems: "flex-start",
          },
          labelPlacementBottom: {
            alignItems: "flex-start",
          },
          label: {
            fontSize: "11px",
            color: palette.common.black,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: palette.themePrimary.primary,
            padding: "0px 0px 0px 0px",
            borderRadius: "5px",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: "6px 0px",
            color: palette.themePrimary.primary,
            width: "16px",
            height: "16px",
            marginRight: "10px",
            "&.MuiRadio-colorError": {
              color: palette.error.main,
            },
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            position: "sticky",
            top: -1,
            zIndex: 1,
            borderTop: "0px ",
          },
          "& .MuiTableCell-head": {
            color: "#1C4588",
            fontWeight: "bold",
            minWidth: "100px",
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            "& .MuiBox-root .MuiTableCell-root.MuiTableCell-sizeMedium.MuiTablePagination-root":
              {
                borderRadius: "5px",
              },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            borderRadius: "5px",
          },
        },
      },
      MuiIcon: {
        styleOverrides: {
          root: {
            boxSizing: "content-box",
            padding: 3,
            fontSize: "1.125rem",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            "& .MuiDialogTitle-root": {
              background:
                "linear-gradient(90deg, #FFFFFF 0%, #FFDBDB 49.5%, #DCC9FA 100%)",
              color: palette.common.white,
            },
            "& .MuiDialog-container .dialogCoseButton": {
              background: palette.common.white,
              color: palette.common.black,
              width: "40px !important",
              height: "40px !important",
              minWidth: "45px",
              minHeight: "45px",
              borderRadius: "100px ",
              fontWeight: "700",
              position: "absolute",
              top: "0",
              right: "0",
              "&:hover": {
                background: palette.common.white,
                color: palette.common.black,
              },
            },
          },
        },
      },
    },
    ...other,
  });

  theme = responsiveFontSizes(theme);

  return theme;
}
