import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutApi } from "redux/features/Auth/loginSlice";
import {
  appBar,
  logoAdminName,
  mainNavbar,
  mainNavbarInnerBox,
} from "styles/AppContainer/NavbarStyle";
import Logo from "../../assets/svg/light/Logo.svg";
import dummyPic from "../../assets/images/profileAvatar.png";
import { useNavigate } from "react-router-dom";
import profileSettingIcon from "assets/svg/light/profile-setting-icon.svg";
import privacyIcon from "assets/svg/light/privacy-icon.svg";
import myteamIcon from "assets/svg/light/myteam-icon.svg";
import chatIcon from "assets/svg/light/chat-icon.svg";
import {
  getLocationsList,
  setActiveLocation,
} from "redux/features/Locations/locationsSlice";
import GenerateBreadcrumbs from "components/GenerateBreadcrumbs";
import Text from "components/common/Typography/Text";
import ReportAnIssueModal from "components/common/Modal/ReportAnIssueModal";
import keyIcon from "assets/svg/light/keyIcon.svg";

const useStyles = makeStyles(() => ({
  root: {
    "& .navbar-select-dropdown": {
      "& .MuiGrid-root.MuiGrid-container": {
        width: "auto !important",
        marginRight: "10px",
      },
    },
  },

  navSearch: {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      backgroundColor: "#ffffff",
      border: "1px solid #E1E1E1",
      borderRadius: "15px",
      height: "30px",
    },
  },
  navIcons: {
    color: "#1C4588",
  },
  logOut: {
    "& .MuiList-root.MuiList-padding.MuiMenu-list": {
      paddingTop: "1px",
      paddingBottom: "10px",
    },
    "& p.MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "400",
    },
    "& .MuiMenuItem-root": {
      paddingLeft: "25px",
      paddingRight: "25px",
      paddingTop: "10px",
      paddingBottom: "10px",
      "&:last-child": {
        paddingLeft: "35px",
      },
    },
  },
}));

const Navbar = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [displayName, setDisplayName] = useState("");
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { locations, activeLocation } = useSelector((state) => state.locations);
  const { isRecording } = useSelector((state) => state.globalStates);
  const { firstName, lastName, username, photo } = useSelector(
    (state) => state.auth,
  );

  const handleLogoutAndClose = () => {
    if (isRecording) {
      const confirmLogout = window.confirm(
        "You have ongoing recording. Are you sure you want to leave?",
      );
      if (confirmLogout) {
        dispatch(logoutApi());
        handleCloseUserMenu();
      }
    } else {
      dispatch(logoutApi());
      handleCloseUserMenu();
    }
  };

  useEffect(() => {
    let displayName = username;
    if (firstName) {
      displayName = `${firstName} ${lastName ?? ""}`;
    }

    setDisplayName(displayName);
  }, [firstName, lastName, username]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [isReportIssueOpen, setIsReportIssueOpen] = useState(false);

  const settings = [
    {
      title: "Profile Settings",
      icon: profileSettingIcon,
      onClick: () => navigate("/settings?tab=0"),
    },
    {
      title: "My Team",
      icon: myteamIcon,
      onClick: () => navigate("/settings?tab=1"),
    },
    {
      title: "Privacy and Security",
      icon: privacyIcon,
      onClick: () => navigate("/settings?tab=2"),
    },
    {
      title: "EMR Credentials",
      icon: keyIcon,
      onClick: () => navigate("/settings?tab=3"),
    },
    {
      title: "Report An Issue",
      icon: chatIcon,
      onClick: () => setIsReportIssueOpen(true),
    },
  ];

  useEffect(() => {
    dispatch(getLocationsList());
  }, [dispatch]);

  useEffect(() => {
    /**
     * If there is no location selected then by 
     default first on will be selected automatically.
    */
    if (locations?.length > 0 && activeLocation?.value === undefined) {
      dispatch(setActiveLocation(locations?.[0]));
    }
  }, [locations]);

  const getInitials = (firstName, lastName) => {
    if (firstName && lastName) {
      return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    } else if (firstName) {
      return firstName.charAt(0).toUpperCase();
    } else if (lastName) {
      return lastName.charAt(0).toUpperCase();
    }
    return ""; // Return an empty string if both are missing
  };

  return (
    <>
      <AppBar sx={appBar} className="navbar">
        <Box sx={mainNavbar(theme)} className={classes.root}>
          <Toolbar sx={{ paddingLeft: "10px !important" }}>
            <Box sx={mainNavbarInnerBox}>
              <Box sx={logoAdminName(theme)}>
                <img
                  src={Logo}
                  data-testid="nephrolyist-logo"
                  alt="logo"
                  style={{ marginRight: "30px", width: "42px", height: "42px" }}
                />
                <GenerateBreadcrumbs id={"bread-crumbs-id"} />
              </Box>
              <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className={classes.logOut}
                  open={Boolean(anchorElUser)}
                  data-testid="menu-logout-id"
                  onClick={handleCloseUserMenu}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "20px",
                    }}>
                    <img
                      src={photo !== null ? photo : dummyPic}
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                      }}
                    />
                    <Typography
                      data-testid={`navbar-display-name`}
                      variant="h2">
                      {displayName}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  {settings.map(({ onClick, icon, title }, index) => (
                    <MenuItem key={index} onClick={() => onClick()}>
                      <Box display="flex">
                        <img src={icon} alt="" style={{ marginRight: "5px" }} />
                        <Typography
                          data-testid={`${title}-button-id`}
                          textAlign="center">
                          {title}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                  <Divider variant="middle" />
                  <MenuItem
                    data-testid={`Logout-button-id`}
                    onClick={() => handleLogoutAndClose()}>
                    <span style={{ color: "#EF4444", fontWeight: "500" }}>
                      Log Out
                    </span>
                  </MenuItem>
                </Menu>

                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Box
                    sx={{
                      borderRadius: "100px",
                      width: "62px",
                      height: "63px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    {photo !== null && (
                      <img
                        src={photo}
                        alt="Profile"
                        data-testid="patient-picture"
                        style={{
                          width: "46px",
                          height: "46px",
                          borderRadius: "100px",
                          backgroundSize: "contain",
                          objectFit: "cover",
                          objectPosition: "50% 0",
                        }}
                      />
                    )}
                    {photo === null && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "20px",
                          background:
                            "linear-gradient(76.4deg, #9969FF 11.51%, #DF7177 92.06%)",
                        }}>
                        <Text variant="h2" color={"white"}>
                          {getInitials(firstName, lastName)}
                        </Text>
                      </Box>
                    )}
                  </Box>
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
      <ReportAnIssueModal
        isOpen={isReportIssueOpen}
        onClose={() => setIsReportIssueOpen(false)}
      />
    </>
  );
};

export default Navbar;
