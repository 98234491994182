import { Box, Card, CardHeader, Grid, Skeleton, useTheme } from "@mui/material";
import TabPill from "components/common/Tabs/TabPill";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PastVisitTab } from "./PastVisitTab";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import PendingIcon from "components/common/SVG/pending";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { fetchEncountersByPatient } from "apiClients/encounters";
import { dateFormat } from "utils/date";
import { PatientDetailHeader } from "components/common/PatientDetailWrapper";
import Button from "components/common/Button/Button";

const useStyles = makeStyles(() => ({
  pastVisitModalroot: {
    "& .MuiTabs-vertical .MuiTabs-flexContainerVertical": {
      alignItems: "end",
      "& .MuiButtonBase-root.MuiTab-root": {
        minHeight: "40px",
        marginTop: "6px",
        justifyContent: "flex-start",
        paddingLeft: "28px",
      },
    },
  },
}));

export const PastVisit = ({
  setOtherEncounter,
  calledFromAppts = false,
  setOpenEndVisit,
  onClose = () => {},
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { patientId } = useParams();
  const classes = useStyles();
  const [mergedData, setMergedData] = useState([]);
  const [patientEncountersData, setPatientEncountersData] = useState([]);
  const [lastEncounter, setLastEncounter] = useState(null);
  const { encounterSuccess } = useSelector((state) => state.encounter);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const {
    data: encounterByPatientData,
    isFetching: isEncounterByPatientLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["encountersByPatient", patientId],
    queryFn: async (data) => fetchEncountersByPatient(patientId, data),
    getNextPageParam: (lastPage) => lastPage?.next,
    staleTime: 10 * 60 * 1000,
    enabled: !!patientId,
  });

  useEffect(() => {
    encounterSuccess &&
      queryClient.invalidateQueries({
        queryKey: ["encountersByPatient", patientId],
      });
  }, [encounterSuccess]);

  const _visibleData = () => {
    return (
      encounterByPatientData?.pages.reduce((acc, curr) => {
        return [...acc, ...curr.results];
      }, []) ?? {}
    );
  };

  useEffect(() => {
    const patientEncountersData = _visibleData();
    patientEncountersData && setPatientEncountersData(patientEncountersData);
  }, [encounterByPatientData]);

  useEffect(() => {
    if (patientEncountersData?.length) {
      let data = patientEncountersData?.map((enc) => ({
        title: moment(enc?.datetime).format(dateFormat),
        showIcon:
          !calledFromAppts &&
          enc?.sign_close_ind !== "c" &&
          enc?.smart_note_data !== null
            ? true
            : false,
        tab: (
          <PastVisitTab
            setOtherEncounter={setOtherEncounter}
            calledFromAppts={calledFromAppts}
            setOpenEndVisit={setOpenEndVisit}
            data={enc}
          />
        ),
      }));
      setMergedData(data);
    }
  }, [patientEncountersData]);

  const observer = useRef(
    new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Fetch more data when the element is in view
          fetchNextPage();
        }
      },
      { threshold: 0.8 },
    ),
  );

  useEffect(() => {
    const currentObserver = observer.current;
    const currentRef = lastEncounter;

    if (currentRef) {
      currentObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        currentObserver.unobserve(currentRef);
      }
    };
  }, [lastEncounter]);

  if (isEncounterByPatientLoading && !hasNextPage) {
    return <DualAxisSkeletonLoader />;
  }

  const selectedEncounter = patientEncountersData[selectedTabIndex];
  const displayResumeVisit =
    !calledFromAppts &&
    selectedEncounter?.sign_close_ind !== "c" &&
    selectedEncounter?.smart_note_data !== null;

  return (
    <>
      <PatientDetailHeader title="Past Visits">
        <Button onClick={() => onClose()} variant="outlined" text="Close" />
        {displayResumeVisit && (
          <Button
            sx={{ marginLeft: "12px", width: "158px" }}
            onClick={() => {
              setOtherEncounter(selectedEncounter);
              setOpenEndVisit(true);
            }}
            text="Resume Visit"
          />
        )}
      </PatientDetailHeader>
      <Box className={classes.pastVisitModalroot}>
        <TabPill
          tabTitle={"VISIT DATE"}
          tabSx={{ color: theme.palette.text.primary }}
          icon={{
            icon: PendingIcon,
            props: {
              fill: theme.palette.text.secondary,
              width: "20",
              height: "20",
              style: { margin: "0px 5px 0px 0px" },
            },
          }}
          titleMargin="24px"
          iconPosition="start"
          noContentDataTestId="empty-past-visit-found"
          onTabChange={(index) => setSelectedTabIndex(index)}
          childrenArray={mergedData}
          defaultValue={0}
          backgroundColor="#ffffff"
          indicatorBackgroundColor={false ? "#1344F1" : "#FC5A5A"}
          tabSelectedColor={false ? "#1344F1" : "#FC5A5A"}
          paddingBtn="0px"
          tabOverFlowDetails={{
            sx: {
              overflow: "auto !important",
              maxHeight: "55vh",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            },
            ref: setLastEncounter,
            isFetching: isEncounterByPatientLoading,
          }}
        />
      </Box>
    </>
  );
};

const DualAxisSkeletonLoader = () => {
  return (
    <Box
      data-testid={"past-visit-loader"}
      sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
      <Box sx={{ width: "100%" }} marginX={1} marginY={1}>
        <Grid container spacing={1}>
          <Grid item lg={4} md={4} xs={4} sm={4}>
            <Card
              elevation={0}
              sx={{
                width: " 100%",
                border: "1px solid #e2e2e4",
                background: "#fafafb",
              }}>
              <CardHeader
                subheader={
                  <Fragment>
                    {[1, 2, 3, 4, 5].map((v, memberIndex) => (
                      <Skeleton
                        key={memberIndex}
                        sx={{ marginBottom: "4px" }}
                        animation="wave"
                        height={50}
                        width="100%"
                      />
                    ))}
                  </Fragment>
                }
              />
            </Card>
          </Grid>
          <Grid item md={8} xs={8} sm={8} lg={8}>
            {[1, 2, 3].map((v, memberIndex) => (
              <Card
                key={memberIndex}
                elevation={0}
                sx={{
                  width: "100%",
                  border: "1px solid #e2e2e4",
                  background: "#fafafb",
                  marginBottom: "6px",
                }}>
                <CardHeader
                  subheader={
                    <>
                      <Skeleton
                        sx={{ marginBottom: "6px" }}
                        animation="wave"
                        height={15}
                        width="75%"
                      />
                      <Skeleton
                        sx={{ marginBottom: "6px" }}
                        animation="wave"
                        height={15}
                        width="50%"
                      />
                      <Skeleton
                        sx={{ marginBottom: "6px" }}
                        animation="wave"
                        height={15}
                        width="25%"
                      />
                    </>
                  }
                />
              </Card>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
