export const ArrowUp = () => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16675 2.83333L1.75008 5.25C1.5973 5.40278 1.40286 5.47917 1.16675 5.47917C0.930637 5.47917 0.736193 5.40278 0.583415 5.25C0.430637 5.09722 0.354248 4.90278 0.354248 4.66667C0.354248 4.43056 0.430637 4.23611 0.583415 4.08333L4.41675 0.25C4.58342 0.0833333 4.77786 0 5.00008 0C5.2223 0 5.41675 0.0833333 5.58342 0.25L9.41675 4.08333C9.56953 4.23611 9.64592 4.43056 9.64592 4.66667C9.64592 4.90278 9.56953 5.09722 9.41675 5.25C9.26397 5.40278 9.06953 5.47917 8.83342 5.47917C8.5973 5.47917 8.40286 5.40278 8.25008 5.25L5.83342 2.83333V9.66667C5.83342 9.90278 5.75355 10.1007 5.59383 10.2604C5.43411 10.4201 5.23619 10.5 5.00008 10.5C4.76397 10.5 4.56605 10.4201 4.40633 10.2604C4.24661 10.1007 4.16675 9.90278 4.16675 9.66667V2.83333Z"
        fill="white"
      />
    </svg>
  );
};
