export const emrCredentialWrapperStyles = {
  width: "100%",
  background: "#fff",
  borderRadius: "24px",
  padding: "20px 30px",
  minHeight: "82vh",
};
export const saveCredentialBoxStyling = {
  background: "#F8F4FF",
  padding: "20px 15px 50px 22px",
  marginTop: "25px",
  marginBottom: "12px",
  borderRadius: "10px",
  border: "1px solid #E2E2E2",
};
export const saveCredentialFormStyles = {
  display: "flex",
  alignItems: "center",
  marginTop: "10px",
};
export const saveCredentialTextStyle = {
  borderBottom: "1px solid #E7E7E7",
  paddingBottom: "10px",
};
export const editBoxStyles = {
  minWidth: "100px",
};
