export const Bold = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_3514_72946"
        maskType="alpha" // Fixed camelCase for React
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3514_72946)">
        <path
          d="M6.7998 19V5H12.3248C13.4081 5 14.4081 5.33333 15.3248 6C16.2415 6.66667 16.6998 7.59167 16.6998 8.775C16.6998 9.625 16.5081 10.2792 16.1248 10.7375C15.7415 11.1958 15.3831 11.525 15.0498 11.725C15.4665 11.9083 15.929 12.25 16.4373 12.75C16.9456 13.25 17.1998 14 17.1998 15C17.1998 16.4833 16.6581 17.5208 15.5748 18.1125C14.4915 18.7042 13.4748 19 12.5248 19H6.7998ZM9.82481 16.2H12.4248C13.2248 16.2 13.7123 15.9958 13.8873 15.5875C14.0623 15.1792 14.1498 14.8833 14.1498 14.7C14.1498 14.5167 14.0623 14.2208 13.8873 13.8125C13.7123 13.4042 13.1998 13.2 12.3498 13.2H9.82481V16.2ZM9.82481 10.5H12.1498C12.6998 10.5 13.0998 10.3583 13.3498 10.075C13.5998 9.79167 13.7248 9.475 13.7248 9.125C13.7248 8.725 13.5831 8.4 13.2998 8.15C13.0165 7.9 12.6498 7.775 12.1998 7.775H9.82481V10.5Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};
