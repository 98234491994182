import {
  Box,
  Grid,
  Paper,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import UpperHeader from "./components/UpperHeader";
import InputField from "components/common/FormComponents/InputField";
import Text from "components/common/Typography/Text";
import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchPatients } from "apiClients/patients";
import { dateFormat } from "utils/date";
import { useDispatch } from "react-redux";
import { getAccountID } from "redux/features/Auth/loginSlice";
import DataTable from "components/common/DataTable/DataTable";
import ActionMenu from "components/common/DataTable/ActionMenu";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from "utils/debouncer";
import PatientListIcon from "assets/svg/PatientListIcon";

const PatientDashboard = () => {
  const customTheme = useTheme();
  const navigate = useNavigate();

  const menuItems = [
    {
      icon: <PatientListIcon />,
      label: "Patient Intake",
      onClick: (row) =>
        navigate(`/patients/${row?.id}`, { state: { intakeFlag: true } }),
    },
  ];
  const columns = [
    {
      key: "given_names",
      label: "First Name",
      width: "150px",
      format: (row) => {
        const firstName = row?.given_names?.first_names?.[0] || "";
        return (
          <Link
            to={`/patients/${row?.id}`}
            style={{
              color: customTheme.palette.common.blue,
              textDecoration: "none",
            }}>
            <Text variant="h3" color={"blue"}>
              {firstName}
            </Text>
          </Link>
        );
      },
    },
    {
      key: "last_name",
      label: "Last Name",
      width: "150px",
      format: (row) => {
        const lastName = row?.last_name || "";
        return (
          <Link
            to={`/patients/${row?.id}`}
            style={{
              color: customTheme.palette.common.blue,
              textDecoration: "none",
              fontWeight: "700",
            }}>
            <Text variant="h3" color={"blue"}>
              {lastName}
            </Text>
          </Link>
        );
      },
    },
    {
      key: "birthdate",
      label: "Birthdate",
      width: "150px",
      format: ({ birthdate }) =>
        birthdate ? moment(birthdate).format(dateFormat) : "-",
    },
    {
      key: "last_encounter",
      label: "Last Encounter",
      width: "150px",
      format: ({ last_encounter }) =>
        last_encounter ? moment(last_encounter).format(dateFormat) : "-",
      sortable: false,
    },
    {
      key: "ckd_status",
      label: "CKD Status",
      width: "150px",
      format: ({ ckd_status }) => ckd_status?.join(", ") || "-",
      sortable: false,
    },
    { key: "emr_id", label: "Patient ID", width: 150 },
    {
      key: "actions",
      label: " ",
      width: "50px",
      sortable: false,
      format: (row) => <ActionMenu row={row} menuItems={menuItems} />,
    },
  ];

  const dispatch = useDispatch();
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [options, setOptions] = useState({
    sort: { field: "last_name", order: "asc" },
    filter: null,
    page: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState(
    columns.find((c) => c.key === "last_name"),
  );

  const { data, isFetching, fetchNextPage } = useInfiniteQuery({
    queryKey: ["patients", options],
    queryFn: async (data) => fetchPatients(options, data),
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const visibleData = () => {
    const latestPage = data?.pages?.[data.pages.length - 1]; // Access the last page
    return latestPage?.results || []; // Return the results from the latest page or an empty array
  };

  const handleSortChange = (field, order) => {
    // Update the sorting state using `setOptions`
    setOptions((prev) => ({
      ...prev,
      sort: { field, order },
    }));
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setOptions((prev) => ({ ...prev, page: newPage }));
  };

  const handleRowsPerPageChange = (newSize) => {
    setPageSize(newSize);
    setPage(0);
    fetchNextPage({ pageParam: 0 });
  };

  const search = (newVal) => {
    setPage(1);
    setOptions((prev) => ({
      ...prev,
      filter: newVal
        ? {
            field: filterType.key,
            operator: "contains",
            value: newVal,
          }
        : null,
      page: null,
    }));
  };

  const debouncedSearch = useMemo(() => debounce(search, 700), [filterType]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleFilterChange = (column) => {
    setFilterType(column);
    if (options.filter?.value) {
      setOptions((prev) => ({ ...prev, filter: null }));
      setSearchTerm("");
    }
  };

  useEffect(() => {
    dispatch(getAccountID());
  }, [dispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UpperHeader title="All Patients" />
        <Paper
          sx={{
            borderRadius: "24px",
            minHeight: "600px",
            background: theme.palette.background,
            overflow: "hidden",
          }}>
          <Toolbar
            sx={{
              padding: "15px 0px",
            }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}>
              <Text
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "700",
                }}
                variant="bodyS">
                {visibleData().length} of {data?.pages[0]?.count || 0}
              </Text>
              <InputField
                onChange={handleSearchChange}
                cols={2}
                label={`Filter By`}
                placeholder={filterType?.label}
                iconDetails={{
                  IconComponent: IconFilterMenu,
                  iconProps: { columns, onFilterChange: handleFilterChange },
                }}
                value={searchTerm}
              />
            </Box>
          </Toolbar>
          <DataTable
            columns={columns}
            data={visibleData()}
            page={page}
            pageSize={pageSize}
            loading={isFetching}
            totalCount={data?.pages[0]?.count || 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSortChange={handleSortChange}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export { PatientDashboard };

const IconFilterMenu = ({ columns = [], onFilterChange }) => {
  const filteredColumns = columns.filter((column) => column.sortable !== false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}>
        <ArrowDropDownIcon />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}>
        {filteredColumns.map((column) => (
          <MenuItem key={column.key} onClick={() => onFilterChange(column)}>
            {column.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
