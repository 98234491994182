import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import Button from "components/common/Button/Button";
import AddTeamMemberModal from "pages/Dashboard/Settings/MyTeam/AddTeamMemberModal";
import EditTeamMemberModal from "pages/Dashboard/Settings/MyTeam/EditTeamMemberModal";
import Text from "components/common/Typography/Text";
import Strips from "components/layout/Strips";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList } from "redux/features/MyTeam/myTeamSlice";
import { getGroupWiseUsers } from "utils/getGroupsWiseUsers";
import threedots from "../../../../assets/svg/light/threedots.svg";
import { SettingsTitle } from "../Wrapper";
import { alignItemsFlexEnd } from "styles";

const MyTeam = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const { account_id } = useSelector((state) => state.auth);
  const { usersList, isUserLoading } = useSelector((state) => state.MyTeams);
  const { groupsOptions } = useSelector((state) => state.options);
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    account_id && dispatch(getUsersList(account_id));
  }, [dispatch, account_id]);

  return (
    <>
      <SettingsTitle title={"My Team"}>
        <Button
          id="add-team-button"
          text="+ Add Team Member"
          variant="outlined"
          onClick={() => setOpenModal(true)}
          sx={{
            width: "179px",
            alignItemsFlexEnd,
          }}
        />
      </SettingsTitle>
      <Box sx={{ width: "100%" }}>
        {isUserLoading ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {[1, 2, 3, 2].map((v, memberIndex) => (
              <Box marginX={1} marginY={1} key={memberIndex}>
                <Card
                  elevation={0}
                  sx={{
                    width: 300,
                    border: "1px solid #e2e2e4",
                    background: "#fafafb",
                  }}>
                  <CardHeader
                    avatar={
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    }
                    title={
                      <Skeleton
                        animation="wave"
                        height={15}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                    }
                    subheader={
                      <Skeleton animation="wave" height={15} width="40%" />
                    }
                  />
                </Card>
              </Box>
            ))}
          </Box>
        ) : (
          <>
            {getGroupWiseUsers(groupsOptions, usersList)?.length ? (
              getGroupWiseUsers(groupsOptions, usersList).map(
                ({ name, data }, index) => (
                  <Box key={index} className="stripCard">
                    <Text id={name} marginY={2} variant="h2" color="black">
                      {name}
                    </Text>
                    <Box
                      className="stripCardData"
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                      }}>
                      {data.map(
                        (
                          {
                            title,
                            photo,
                            designation,
                            firstName,
                            username,
                            lastName,
                            id,
                          },
                          memberIndex,
                        ) => (
                          <Strips
                            selectedID={selectedID}
                            setSelectedID={setSelectedID}
                            setOpenEditModal={setOpenEditModal}
                            key={memberIndex}
                            name={`${title ?? ""} ${firstName ? firstName + " " + lastName : username}`}
                            role={designation ?? ""}
                            profilePic={photo}
                            options={threedots}
                            id={id}
                          />
                        ),
                      )}
                    </Box>
                  </Box>
                ),
              )
            ) : (
              <Box>No records found</Box>
            )}
          </>
        )}
      </Box>
      <EditTeamMemberModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        userId={selectedID}
      />
      <AddTeamMemberModal open={openModal} setOpen={setOpenModal} />
    </>
  );
};

export default MyTeam;
