import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Fragment, useEffect, useRef, useState } from "react";
import Text from "../Typography/Text";
import { ErrorTooltip } from "../Tooltips/ErrorTooltip";
import CheckIcon from "@mui/icons-material/Check";

const useStyles = makeStyles(() => ({
  autoComplete: (props) => ({
    "& input": {
      color: props.color,
      fontWeight: props.fontWeight,
      borderRadius: props.borderRadius,
    },
  }),
}));

const SelectField = ({
  // Set this to make this a controlled component
  value,
  // Set this to make this an uncontrolled component
  defaultValue,
  // The label for the input field. This will be displayed above the input field.
  label,
  // The label for the input field. This will be displayed inside the input field (and will float when the input field is focused).
  inputLabel,
  // The name of the input field. This will be used to identify the input field in the form.
  name,
  disabled,
  options = [],
  loading = false,
  helperText = "",
  cols = 6,
  fontWeight = 400,
  formik,
  onChange,
  onSearch,
  isMulti = false,
  color = "",
  placeholder = "",
  borderRadius = "10px",
  border = "1px solid #ccc",
  labelVariant = "bodyS",
  // Remove the clear icon from the select field
  disableClearable = false,
  showError = false,
  onClear = () => {},
  /* this props deals with clearing of text
   on outside click of selectfield*/
  freeSolo = false,
  // If true, the popup will open on input focus.
  openOnFocus = true,
  /* this prop is to stop filtering dropdown elements
   when filtration is not needed (prevents extra rendering) */
  enableFiltering = true,
  extendedSearchOptions = [],
  showMoreInLabel = { show: false, value: "" },
  infiniteScrollDetails = { hasMoreToFetch: false, fetchMore: () => {} },
  // Set custom validators that will run on change. These allow us to add custom validation logic to the input field and control the validation styling.
  validators = [],
  sx = {},
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value?.value ?? value ?? "");
  const [fetchingMore, setFetchingMore] = useState(false);
  const inputRef = useRef(null);

  const classes = useStyles({ color, fontWeight, borderRadius });

  const [fieldError, setFieldError] = useState(
    formik?.errors[name] || helperText,
  );
  const [fieldTouched, setFieldTouched] = useState(
    formik?.touched[name] || showError,
  );

  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    if (value) {
      setInnerValue(value);
    }
  }, [value]);

  useEffect(() => {
    setFieldError(formik?.errors[name] || helperText);
    setFieldTouched(formik?.touched[name] || showError);
  }, [formik, showError]);

  const handleChange = (event, value) => {
    if (formik) {
      if (isMulti) {
        formik?.setFieldValue(
          name,
          value?.map((option) => option?.value),
        );
      } else {
        formik?.setFieldValue(name, value?.value || null);
      }
    }

    setInnerValue(value);
    validate(value);

    if (onChange) {
      onChange(event, value);
    }
  };

  useEffect(() => {
    setFetchingMore(false);
  }, [options]);

  // to api call fetching for more scroll
  function handleMoreFetching() {
    if (!fetchingMore) {
      infiniteScrollDetails.fetchMore();
      setFetchingMore(true);
    }
  }
  useEffect(() => {
    if (inputValue.length === 0) {
      onClear();
    }
  }, [inputValue]);

  const validate = (value) => {
    setFieldError("");
    inputRef.current?.setCustomValidity("");
    if (validators.length) {
      validators.forEach((validator) => {
        const error = validator(value);
        if (error) {
          setFieldError(error);
          inputRef.current?.setCustomValidity(error);
        }
      });
    }
  };

  useEffect(() => {
    // Let one event loop run to make sure the input has rendered.
    setTimeout(() => {
      validate(inputValue);
    });
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={cols}
      md={cols}
      lg={cols}
      xl={cols}
      xxl={cols}
      flex="0">
      {label && (
        <Text
          variant={labelVariant}
          fontWeight={500}
          component="label"
          marginY={1}>
          {label}
        </Text>
      )}
      <ErrorTooltip
        arrow
        title={fieldTouched && fieldError ? fieldError : ""}
        placement="right">
        <FormControl fullWidth variant="outlined">
          <Autocomplete
            className={classes.autoComplete}
            openOnFocus={openOnFocus}
            multiple={isMulti}
            id={name}
            name={name}
            open={open}
            disabled={disabled}
            disableClearable={disableClearable}
            freeSolo={freeSolo}
            onBlur={onClear}
            defaultValue={defaultValue}
            value={innerValue}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              // Check if the change is due to user input, not option selection
              if (event?.type === "change") {
                onSearch && onSearch(newInputValue);
              }

              setFieldTouched(true);
              validate(newInputValue);
            }}
            options={
              options?.length > 0
                ? options
                : /*free solo is to preserve the selectfield and to show "no options" while free solo is true */
                  freeSolo && !loading
                  ? [{ disabled: true, label: "No options", value: "" }]
                  : []
            }
            loading={loading}
            filterOptions={
              enableFiltering
                ? (options, { inputValue }) =>
                    options?.filter((option) => {
                      return (
                        option.label
                          ?.toLowerCase()
                          .includes(inputValue.toLowerCase()) ||
                        (extendedSearchOptions.length > 0 &&
                          extendedSearchOptions.some((exOptions) =>
                            option[exOptions]
                              ?.toString()
                              .toLowerCase()
                              .includes(inputValue.toLowerCase()),
                          ))
                      );
                    })
                : (options) => options
            }
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option?.label || ""
            }
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value || option === value
            }
            onChange={(e, v) => handleChange(e, v)}
            ListboxProps={{
              // scroll in the dropdown will only work if needed or more pages are pending
              onScroll: infiniteScrollDetails.hasMoreToFetch
                ? (event) => {
                    if (
                      Math.abs(
                        event.currentTarget.scrollHeight -
                          event.currentTarget.scrollTop -
                          event.currentTarget.clientHeight,
                      ) <= 1
                    ) {
                      handleMoreFetching();
                    }
                  }
                : undefined,
            }}
            renderOption={({ key, ...props }, option, state) => {
              let { onClick, ...rest } = props;
              return (
                <Fragment key={state?.index}>
                  <Box {...(option?.["disabled"] ? rest : props)}>
                    <li
                      style={{
                        width: "100%",
                        padding: "2px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}>
                      <span
                        style={{
                          fontSize: "13px",
                          color: option?.["disabled"]
                            ? theme.palette.common.grey
                            : theme.palette.common.black,
                        }}>
                        {showMoreInLabel?.show ? (
                          <>
                            <b>{option?.label}</b>
                            {` - ${option[showMoreInLabel?.value]}`}
                          </>
                        ) : (
                          option?.label
                        )}
                      </span>
                      {option?.["selected"] ? (
                        <CheckIcon sx={{ fontSize: "16px" }} />
                      ) : null}
                    </li>
                  </Box>
                  {/* Scroller that will always show on the last except below conditions */}
                  {infiniteScrollDetails?.hasMoreToFetch &&
                  state?.index + 1 === options?.length ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <CircularProgress
                        sx={{ marginLeft: "4px" }}
                        size={"25px"}
                        color="inherit"
                      />
                    </Box>
                  ) : null}
                </Fragment>
              );
            }}
            renderInput={(params) => {
              inputRef.current = params.inputProps.ref.current;
              return (
                <TextField
                  {...params}
                  sx={{
                    border: border,
                    borderRadius: borderRadius,
                    ...sx,
                  }}
                  name={name}
                  size="small"
                  data-testid={`${name}-id`}
                  variant="outlined"
                  label={inputLabel}
                  onBlur={() => setFieldTouched(true)}
                  error={fieldTouched && !!fieldError}
                  color="info"
                  placeholder={placeholder}
                />
              );
            }}
          />
        </FormControl>
      </ErrorTooltip>
    </Grid>
  );
};

export default SelectField;
