import moment from "moment";
import { Matches, Max, MaxLength, Required } from "validations/validators";

const phoneNumberRegex = new RegExp(
  /^(\+)?(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/,
);

export const ProfileInputs = [
  {
    element: "input",
    type: "text",
    cols: 6,
    label: "First Name",
    name: "first_name",
    placeHolder: "First Name",
  },
  {
    element: "input",
    type: "text",
    cols: 6,
    label: "Last Name",
    name: "last_name",
    placeHolder: "Last Name",
  },
  {
    element: "input",
    type: "date",
    cols: 6,
    label: "DOB",
    name: "birthdate",
    validators: [Required, Max(moment())],
  },
  {
    element: "input",
    type: "text",
    cols: 6,
    label: "National Provider ID",
    name: "national_provider_identifier",
    placeHolder: "National Provider ID",
    validators: [Required, MaxLength(10)],
  },
  {
    element: "input",
    type: "text",
    cols: 6,
    label: "Phone Number",
    name: "phone_number",
    placeHolder: "+1 (123) 123-1234",
    validators: [
      Matches(phoneNumberRegex, "Please enter a valid phone number"),
    ],
  },
];
