import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isfirstLogin: true,
  navigationPathisRecording: false,
  currentNavigationPath: null,
};

const name = "globalStates";

export const globalSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFirstLogin: (state) => {
      state.isfirstLogin = false;
    },
    setRecording: (state, { payload }) => {
      state.isRecording = payload;
    },
    setCurrentNavigationPath: (state, { payload }) => {
      state.currentNavigationPath = payload;
    },
  },
});

export const { setFirstLogin, setCurrentNavigationPath, setRecording } =
  globalSlice.actions;

export default globalSlice.reducer;
