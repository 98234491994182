export const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none">
      <path
        d="M4.16675 8.33329V1.49996C4.16675 1.26385 4.24661 1.06593 4.40633 0.906209C4.56605 0.746487 4.76397 0.666626 5.00008 0.666626C5.23619 0.666626 5.43411 0.746487 5.59383 0.906209C5.75355 1.06593 5.83342 1.26385 5.83342 1.49996V8.33329L8.25008 5.91663C8.40286 5.76385 8.5973 5.68746 8.83342 5.68746C9.06953 5.68746 9.26397 5.76385 9.41675 5.91663C9.56953 6.0694 9.64592 6.26385 9.64592 6.49996C9.64592 6.73607 9.56953 6.93051 9.41675 7.08329L5.58342 10.9166C5.41675 11.0833 5.2223 11.1666 5.00008 11.1666C4.77786 11.1666 4.58342 11.0833 4.41675 10.9166L0.583415 7.08329C0.430637 6.93051 0.354248 6.73607 0.354248 6.49996C0.354248 6.26385 0.430637 6.0694 0.583415 5.91663C0.736193 5.76385 0.930637 5.68746 1.16675 5.68746C1.40286 5.68746 1.5973 5.76385 1.75008 5.91663L4.16675 8.33329Z"
        fill="white"
      />
    </svg>
  );
};
