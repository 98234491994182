import { Box, useTheme, Grid } from "@mui/material";
import InputField from "components/common/FormComponents/InputField";
import { SubFormTitle } from "../common/SubFormTitle";
import { useContext, useEffect, useState } from "react";
import Text from "components/common/Typography/Text";
import { SubForm } from "../common/SubForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addPayer,
  deletePayer,
  fetchPayers,
  updatePayer,
} from "apiClients/payer";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Button from "components/common/Button/Button";
import { Required } from "validations/validators";
import { DatePickerInput } from "components/common/FormComponents/DatePickerInput";
import moment from "moment";
import { formatErrorsToAlerts } from "../common/utils";
import { IconButton } from "components/common/Button/IconButton";
import { Trash } from "components/common/SVG/Trash";

const secondaryInsuranceInputs = [
  {
    label: "Name",
    key: "payer_name",
    cols: 4,
    validators: [Required],
  },
  {
    label: "Policy Number",
    key: "policy_number",
    cols: 4,
    validators: [Required],
  },
  {
    label: "Group Number",
    key: "group_number",
    cols: 4,
    validators: [Required],
  },
];

export const InsuranceReview = ({ errors }) => {
  const queryClient = useQueryClient();
  const [values, setValues] = useState({});
  const [addingPrimary, setAddingPrimary] = useState(false);
  const { registerForm, patientId } = useContext(IntakeFormContext);

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      if (dirtyValue.id) {
        if (dirtyValue.delete) {
          // If delete is set we need to delete the medication.
          return deletePayer(patientId, dirtyValue.id);
        } else {
          // If no delete is set, we need to update the medication since it is dirty (changed).
          return updatePayer(patientId, dirtyValue.id, dirtyValue);
        }
      } else {
        // If no ID is present, it is a new medication and we need to add it.
        return addPayer(patientId, dirtyValue);
      }
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["payers", patientId],
      });
    },
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ["payers", patientId],
      });
    };
  }, [queryClient, patientId]);

  const { data, isLoading } = useQuery({
    queryKey: ["payers", patientId],
    queryFn: async () => await fetchPayers(patientId),
  });

  const mapToFormFn = (results) => {
    let primaryInsurance = {};
    let secondaries = [];
    for (let result of results) {
      if (result.primary_insurance) {
        primaryInsurance = result;
      } else {
        secondaries.push(result);
      }
    }

    return {
      ...primaryInsurance,
      secondaries,
    };
  };

  const mapToApiFn = (values) => {
    const { secondaries = [], ...rest } = values;
    const primaryInsurance = rest;
    const secondaryInsurances = secondaries;

    if (Object.keys(primaryInsurance).length === 0) {
      return [];
    }

    const all = [primaryInsurance, ...secondaryInsurances];
    return all;
  };

  const handleSubmit = async (dirtyValues) => {
    const operations = dirtyValues.map((dirtyValue) => {
      // Because of the validations on the backend, we need to format the dates to the this format.
      const formattedValue = {
        ...dirtyValue,
        guarantor_birthdate: dirtyValue.guarantor_birthdate
          ? moment(dirtyValue.guarantor_birthdate).format("YYYY-MM-DD")
          : null,
      };
      return mutation.mutateAsync(formattedValue);
    });

    return Promise.allSettled(operations);
  };

  const getSpacerHeight = () => {
    if (values.payer_name || addingPrimary) {
      return "25vh";
    } else {
      return "50vh";
    }
  };

  const showPrimary = (values.payer_name || addingPrimary) && !values.delete;

  let includeHiddenFields = false;
  if (values.payer_name) {
    // If the primary insurance already exists we always need to show the hidden fields.
    includeHiddenFields = true;
  } else if (showPrimary) {
    // If the insurance does not already exist then only show the hidden fields if the insurance is being added.
    includeHiddenFields = true;
  }

  const onPrimaryDelete = (val) => {
    setValues((prev) => ({
      ...prev,
      delete: true,
    }));
  };

  const onAddPrimary = () => {
    if (values.delete) {
      // primary insurance was removed but not submitted yet, so we need to remove the delete flag.
      setValues((prev) => {
        delete prev.delete;
        return { ...prev };
      });
    } else {
      setAddingPrimary(true);
    }
  };

  useEffect(() => {
    if (data) {
      const mapped = mapToFormFn(data.results);
      // Insurance has a special case where we need to set the initial values without mapping because we will need to map it back to the API format via the mapper fn.
      registerForm("insurance", handleSubmit, data.results, mapToApiFn);
      setValues(mapped);
    }
  }, [data]);

  return (
    <>
      <Text variant="h1">Insurance Review</Text>
      <Grid container spacing={1} sx={{ my: 1 }}>
        {includeHiddenFields && (
          <>
            <input type="hidden" name={`insurance.id`} value={values.id} />
            {values.delete && (
              <input
                type="hidden"
                name={`insurance.delete`}
                value={values.delete}
              />
            )}
            <input
              type="hidden"
              name="insurance.primary_insurance"
              value={true}
            />
          </>
        )}
        <SkeletonWrapper
          condition={!isLoading}
          props={[{ width: "100%", height: "40px" }]}>
          {showPrimary ? (
            <>
              <SubFormTitle
                tag="h2"
                title="Primary Insurance"
                sx={{ marginLeft: "12px" }}
              />
              <InputField
                value={values.payer_name}
                name="insurance.payer_name"
                inputLabel="Policy holder"
                cols={3}
                validators={[Required]}
              />
              <InputField
                value={values.policy_number}
                name="insurance.policy_number"
                inputLabel="Policy Number"
                cols={3}
                validators={[Required]}
                copyable
              />
              <InputField
                value={values.group_number}
                name="insurance.group_number"
                inputLabel="Group Number"
                cols={3}
                validators={[Required]}
                copyable
              />

              <Grid
                item
                xs={1}
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  bottom: "4px",
                }}>
                <IconButton
                  variant="outlined"
                  onClick={() => onPrimaryDelete()}>
                  <Trash />
                </IconButton>
              </Grid>

              <GuarantorForm values={values} />
            </>
          ) : (
            <>
              <SkeletonWrapper
                condition={!isLoading}
                props={[{ width: "100%", height: "40px" }]}>
                <Button
                  text="+ Add Primary Insurance"
                  variant="text"
                  sx={{ justifyContent: "flex-start" }}
                  onClick={() => onAddPrimary()}
                />
              </SkeletonWrapper>
            </>
          )}
        </SkeletonWrapper>
        {showPrimary && (
          <Grid item lg={12}>
            <SubFormTitle
              tag="h2"
              title="Secondary Insurance"
              sx={{ marginTop: "0px", marginLeft: "10px" }}
            />
            <SubForm
              formKey="insurance.secondaries"
              subSections={secondaryInsuranceInputs}
              dataLoading={isLoading}
              data={values.secondaries ?? []}
            />
          </Grid>
        )}
      </Grid>
      {formatErrorsToAlerts(errors)}
      {/* Spacer for the end of the form to allow scrolling to the last section */}
      <Box sx={{ height: getSpacerHeight() }}></Box>
    </>
  );
};

const GuarantorForm = ({ values }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: "24px",
        backgroundColor: theme.palette.common.lightGrey,
        borderRadius: "8px",
        margin: "12px 8px",
      }}>
      <SubFormTitle
        tag="h2"
        title="Guarantor Information"
        sx={{ marginLeft: "12px" }}
      />
      <InputField
        value={values.guarantor_first_name}
        name="insurance.guarantor_first_name"
        inputLabel="First Name"
        cols={2}
      />
      <InputField
        value={values.guarantor_last_name}
        name="insurance.guarantor_last_name"
        inputLabel="Last Name"
        cols={2}
      />
      <InputField
        value={values.guarantor_phone_number}
        name="insurance.guarantor_phone_number"
        inputLabel="Phone Number"
        cols={2}
      />
      <InputField
        value={values.guarantor_social_security_number}
        name="insurance.guarantor_social_security_number"
        inputLabel="Social Security Number"
        cols={2}
      />
      <DatePickerInput
        value={values.guarantor_birthdate}
        name="insurance.guarantor_birthdate"
        inputLabel="Birthdate"
        cols={2}
        validators={[Required]}
      />
      <InputField
        value={values.guarantor_address}
        name="insurance.guarantor_address"
        inputLabel="Address"
        cols={4}
      />
      <InputField
        value={values.guarantor_city}
        name="insurance.guarantor_city"
        inputLabel="City"
        cols={2}
      />
      <InputField
        value={values.guarantor_state}
        name="insurance.guarantor_state"
        inputLabel="State"
        cols={2}
      />
      <InputField
        value={values.guarantor_zipcode}
        name="insurance.guarantor_zipcode"
        inputLabel="Zipcode"
        cols={2}
      />
    </Grid>
  );
};
