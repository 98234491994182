import { forwardRef } from "react";

export const Person = forwardRef((props, ref) => {
  return (
    <svg
      ref={ref}
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4093_1519"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32">
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4093_1519)">
        <path
          d="M15.9999 16.0024C14.5333 16.0024 13.2777 15.4801 12.2333 14.4357C11.1888 13.3912 10.6666 12.1357 10.6666 10.669C10.6666 9.20236 11.1888 7.9468 12.2333 6.90236C13.2777 5.85792 14.5333 5.33569 15.9999 5.33569C17.4666 5.33569 18.7221 5.85792 19.7666 6.90236C20.811 7.9468 21.3333 9.20236 21.3333 10.669C21.3333 12.1357 20.811 13.3912 19.7666 14.4357C18.7221 15.4801 17.4666 16.0024 15.9999 16.0024ZM5.33325 26.669V22.9357C5.33325 22.1801 5.5277 21.4857 5.91659 20.8524C6.30547 20.219 6.82214 19.7357 7.46659 19.4024C8.84436 18.7135 10.2444 18.1968 11.6666 17.8524C13.0888 17.5079 14.5333 17.3357 15.9999 17.3357C17.4666 17.3357 18.911 17.5079 20.3333 17.8524C21.7555 18.1968 23.1555 18.7135 24.5333 19.4024C25.1777 19.7357 25.6944 20.219 26.0833 20.8524C26.4721 21.4857 26.6666 22.1801 26.6666 22.9357V26.669H5.33325ZM7.99992 24.0024H23.9999V22.9357C23.9999 22.6912 23.9388 22.469 23.8166 22.269C23.6944 22.069 23.5333 21.9135 23.3333 21.8024C22.1333 21.2024 20.9221 20.7524 19.6999 20.4524C18.4777 20.1524 17.2444 20.0024 15.9999 20.0024C14.7555 20.0024 13.5221 20.1524 12.2999 20.4524C11.0777 20.7524 9.86659 21.2024 8.66658 21.8024C8.46659 21.9135 8.30547 22.069 8.18325 22.269C8.06103 22.469 7.99992 22.6912 7.99992 22.9357V24.0024ZM15.9999 13.3357C16.7333 13.3357 17.361 13.0746 17.8833 12.5524C18.4055 12.0301 18.6666 11.4024 18.6666 10.669C18.6666 9.93569 18.4055 9.30792 17.8833 8.78569C17.361 8.26347 16.7333 8.00236 15.9999 8.00236C15.2666 8.00236 14.6388 8.26347 14.1166 8.78569C13.5944 9.30792 13.3333 9.93569 13.3333 10.669C13.3333 11.4024 13.5944 12.0301 14.1166 12.5524C14.6388 13.0746 15.2666 13.3357 15.9999 13.3357Z"
          fill="#2D3748"
        />
      </g>
    </svg>
  );
});
