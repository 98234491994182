import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Grid,
  styled,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { ErrorTooltip } from "../Tooltips/ErrorTooltip";

const CustomRadioGroup = styled(RadioGroup)(
  ({ theme }) => `
    padding: 10px;
    :has(.MuiRadio-colorError) {
        border-radius: 10px;
        border: 1px solid ${theme.palette.error.main};
    }
`,
);

export const RadioField = ({
  value,
  name,
  cols = 6,
  label,
  options,
  onChange,
  // Set custom validators that will run on change. These allow us to add custom validation logic to the input field and control the validation styling.
  validators = [],
}) => {
  const inputRefs = useRef([]);
  const [fieldError, setFieldError] = useState("");
  const [fieldTouched, setFieldTouched] = useState(false);
  // Inner value is used to store the value of the input field when no onChange is provided
  const [innerValue, setInnerValue] = useState(value);

  const handleChange = (event) => {
    const { value } = event.target;
    setFieldTouched(true);
    if (onChange) {
      onChange(event);
    } else {
      setInnerValue(value);
    }
    validate(value);
  };

  const validate = useCallback(
    (value) => {
      setFieldError(null);
      inputRefs.current.forEach((ref) => {
        ref.setCustomValidity("");
      });
      if (validators.length) {
        for (const validator of validators) {
          const err = validator(value);
          if (err) {
            setFieldError(err);
            inputRefs.current.forEach((ref) => {
              ref.setCustomValidity(err);
            });
          }
        }
      }
    },
    [validators],
  );

  useEffect(() => {
    if (inputRefs.current.length === options.length) {
      validate(value);
    }
  }, []);

  return (
    <Grid
      item
      xs={cols}
      sm={cols}
      md={cols}
      lg={cols}
      sx={{
        display: "flex",
        alignItems: !label ? "flex-end" : "center",
        justifyContent: "space-around",
      }}>
      <ErrorTooltip
        arrow
        title={fieldTouched && fieldError ? fieldError : ""}
        placement="right">
        <FormControl fullWidth component="fieldset">
          {label ? <FormLabel component="legend">{label}</FormLabel> : ""}
          <CustomRadioGroup
            row
            aria-label={label}
            name={name}
            onChange={handleChange}
            sx={{ width: "100%" }}
            value={innerValue}>
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.value}
                control={
                  <Radio
                    inputProps={{ onBlur: () => setFieldTouched(true) }}
                    inputRef={(ref) => (inputRefs.current[index] = ref)}
                    color={fieldError && fieldTouched ? "error" : "info"}
                  />
                }
                label={option.label}
                sx={{ justifyContent: "center", flex: 1 }}
              />
            ))}
          </CustomRadioGroup>
        </FormControl>
      </ErrorTooltip>
    </Grid>
  );
};
